:root {
  --color-white: #fff;
  --color-white-1: #f8f8fd;
  --color-white-2: #fff;
  --color-gray: #bac1d6;
  --color-gray-1: #8b8c8f;
  --color-gray-2: #25272e;
  --color-black: #000;
  --color-header: linear-gradient(90deg, #02002400 0%, #0004 100%);
  --color-black-header: #0000004d;
  --color-red: #bb2649;
  --color-red-1: #bb2649;
  --color-red-2: #bb264947;
  --color-red-3: #bb26491f;
  --color-red-4: #ffffff3d;
  --color-red-5: #8c1833;
  --color-blue: #283f8e;
  --color-blue-1: #283f8e14;
  --color-violet: #25272e;
  --color-violet-1: #f8f8fd47;
  --color-tg: #64c8fa;
  --gradient-dropdown: linear-gradient(133.63deg, #bb264900 47.645%, #bb2649 152.218%), linear-gradient(29.28deg, #3a2f50 3.827%, #272024 58.696%);
  --gradient-menu: linear-gradient(133.63deg, #bb264900 47.645%, #6e172b 152.218%), linear-gradient(29.28deg, #3a2f50 3.827%, #272024 58.696%);
  --gradient-modal: linear-gradient(90deg, #393050 0%, #281b2a 100%);
  --gradient-dropdown-btn: linear-gradient(90deg, #020024 0%, #bb2649 0%, #1000ff 100%);
  --bg: linear-gradient(185deg, #252834 89%, #1e152a 100%, #252840 73%);
  --box-shadow: #503fcd80 0 1px 30px;
}

* {
  box-sizing: border-box;
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  font-family: Manrope, sans-serif;
}

ul {
  padding-inline: 0;
  list-style-type: none;
}

a {
  color: var(--color-white);
  text-decoration: none;
}

h1, h2, h3 {
  margin-block: 0;
  margin-inline: 0;
}

.management {
  background: var(--bg);
  flex-direction: column;
  align-items: center;
  display: flex;
  overflow: hidden;
}

@media (width >= 1351px) {
  .management {
    padding-inline: 0;
  }
}

.management__header-right-side {
  justify-content: flex-end;
  align-items: center;
  inline-size: 100%;
  display: flex;
}

.management__header-icons {
  gap: 20px;
  padding-inline: 30px;
  display: flex;
}

@media (width <= 576px) {
  .management__header-icons {
    padding-inline: 15px;
  }
}

@media (width >= 993px) and (width <= 1350px) {
  .management__header-icons {
    padding-inline: 15px;
  }
}

.management__header-icon img {
  width: 30px;
  height: 30px;
}

.management__burger {
  backdrop-filter: blur(2px);
  z-index: 11;
  background: #25272ea1;
  justify-content: space-between;
  align-items: center;
  inline-size: 100%;
  padding-block: 10px;
  padding-inline: 20px 6px;
  display: flex;
  position: fixed;
}

@media (width >= 769px) {
  .management__burger {
    block-size: 120px;
    padding-inline: 20px 10px;
  }
}

@media (width >= 1351px) {
  .management__burger {
    padding-inline: 80px;
  }
}

.management__burger-wrap {
  background: var(--gradient-menu);
  inline-size: 100%;
}

.management__logo {
  block-size: 59px;
  inline-size: 48px;
}

@media (width >= 769px) {
  .management__logo {
    block-size: 100px;
    inline-size: 80px;
  }
}

.management__logo-link {
  z-index: 10;
}

.management__button-wrapper {
  z-index: 120;
  padding-inline-end: 14px;
}

@media (width >= 769px) {
  .management__button-wrapper {
    padding-inline-end: 0;
  }
}

@media (width <= 576px) {
  .management__button-wrapper {
    padding-inline-end: 0;
  }
}

.management__button {
  background: var(--color-red);
  color: var(--color-white);
  cursor: pointer;
  text-wrap: nowrap;
  z-index: 10;
  border: none;
  border-radius: 48px;
  padding-block: 8px;
  padding-inline: 20px;
  font-size: .875rem;
  font-weight: 700;
  line-height: 150%;
}

@media (width >= 1351px) {
  .management__button:hover {
    background: var(--color-red-5);
    transition-duration: .1s;
  }
}

@media (width >= 769px) {
  .management__button {
    margin-inline-end: 50px;
    padding-block: 16px;
    padding-inline: 40px;
    font-size: 16px;
  }
}

@media (width >= 993px) {
  .management__button {
    margin-inline-end: 0;
  }
}

@media (width <= 576px) {
  .management__button-full {
    display: none;
  }
}

.management__burger-container {
  cursor: pointer;
  display: inline-block;
  position: fixed;
  inset-inline-end: 20px;
}

@media (width >= 1351px) {
  .management__burger-container {
    display: none;
  }
}

.management__burger-line1, .management__burger-line2, .management__burger-line3 {
  background: var(--color-white);
  border-radius: 5px;
  block-size: 5px;
  inline-size: 36px;
  margin-block: 6px;
  margin-inline: 0;
  transition: all .4s;
}

.change .management__burger-line1 {
  border-radius: 3px;
  transform: rotate(-45deg)translate(-9px, 6px);
}

.change .management__burger-line2 {
  opacity: 0;
}

.change .management__burger-line3 {
  border-radius: 3px;
  transform: rotate(45deg)translate(-8px, -8px);
}

.management__try-out {
  text-align: center;
  padding-block-start: 184px;
}

@media (width >= 769px) {
  .management__try-out {
    padding-block-start: 336px;
  }
}

@media (width >= 1351px) {
  .management__try-out {
    padding-block-start: 300px;
  }
}

.management__title-main {
  color: var(--color-white);
  text-transform: uppercase;
  padding-block-end: 16px;
  font-size: 40px;
  font-weight: 900;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__title-main {
    padding-block-end: 28px;
    font-size: 52px;
  }
}

@media (width >= 1351px) {
  .management__title-main {
    padding-block-end: 20px;
    font-size: 68px;
  }
}

.management__subtitle-main {
  color: var(--color-white);
  padding-block-end: 40px;
  font-size: 18px;
  font-weight: 800;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__subtitle-main {
    padding-block-end: 24px;
    font-size: 28px;
  }
}

@media (width >= 1351px) {
  .management__subtitle-main {
    padding-block-end: 30px;
    font-size: 40px;
  }
}

.management__paragraph-main {
  color: var(--color-white);
  padding-block-end: 40px;
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__paragraph-main {
    font-size: 20px;
  }
}

@media (width >= 1351px) {
  .management__paragraph-main {
    font-size: 24px;
  }
}

.management__button-main {
  cursor: pointer;
  color: #fff;
  background: var(--color-red);
  border: none;
  border-radius: 48px;
  padding-block: 16px;
  padding-inline: 65px;
  font-size: 20px;
  font-weight: 700;
}

@media (width <= 576px) {
  .management__button-main {
    font-size: 16px;
  }
}

@media (width >= 769px) {
  .management__button-main {
    padding-inline: 40px;
  }
}

@media (width >= 1351px) {
  .management__button-main:hover {
    background: var(--color-red-5);
    transition-duration: .1s;
  }
}

.management__assets {
  color: var(--color-white);
  padding-block-start: 110px;
}

@media (width >= 769px) {
  .management__assets {
    padding-block-start: 100px;
  }
}

@media (width >= 1351px) {
  .management__assets {
    padding-block-start: 120px;
  }
}

.management__assets-header {
  inline-size: 100%;
  padding-block-end: 28px;
  font-size: 20px;
  font-weight: 700;
}

@media (width >= 769px) {
  .management__assets-header {
    max-width: 65%;
    font-size: 28px;
    font-weight: 900;
    line-height: 150%;
    display: block;
  }
}

@media (width >= 1351px) {
  .management__assets-header {
    max-inline-size: 1070px;
    padding-block-end: 40px;
    padding-inline-start: 96px;
    font-size: 40px;
  }
}

.management__assets-mobile-only {
  text-transform: uppercase;
  padding-block-end: 28px;
  font-size: 20px;
  font-weight: 800;
}

@media (width >= 769px) {
  .management__assets-mobile-only {
    display: none;
  }
}

.management__assets-cards {
  grid-gap: 20px;
  grid-template-columns: 1fr;
  display: grid;
}

@media (width >= 768px) {
  .management__assets-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 993px) {
  .management__assets-cards {
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.management__assets-card-item {
  background: var(--color-white);
  color: var(--color-black);
  border-radius: 48px;
}

@media (width >= 769px) and (width <= 992px) {
  .management__assets-card-item:last-child {
    grid-column: span 2;
  }
}

.management__assets-card-icon {
  inline-size: 76px;
}

.management__assets-card-description {
  padding-block: 29px 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__assets-card-description {
    font-size: 20px;
  }
}

@media (width >= 1351px) {
  .management__assets-card-description {
    font-size: 28px;
  }
}

.management__assets-card-info-wrap {
  flex-direction: column;
  display: flex;
}

.management__assets-card-item-wrap {
  flex-direction: column;
  height: 100%;
  padding-block: 40px;
  padding-inline: 28px;
  display: flex;
}

@media (width >= 769px) {
  .management__assets-card-item-wrap {
    justify-content: space-between;
    padding-inline: 40px;
  }
}

@media (width >= 1351px) {
  .management__assets-card-item-wrap {
    align-items: unset;
    min-height: 400px;
    padding-inline: 40px;
  }
}

.management__assets-card-btn {
  background: var(--color-red-3);
  color: var(--color-red-1);
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 48px;
  padding-block: 16px;
  padding-inline: 20px;
  font-size: 20px;
  font-weight: 700;
}

.management__assets-card-btn:hover {
  background: var(--color-red-2);
}

.management__pros {
  background: var(--color-violet-1);
  color: var(--color-white);
  border-radius: 48px;
  margin-block-start: 100px;
}

@media (width <= 768px) {
  .management__pros {
    margin-block-start: 90px;
  }
}

@media (width >= 1351px) {
  .management__pros {
    margin-block-start: 120px;
  }
}

.management__pros-description {
  padding-block: 60px 0;
  padding-inline: 28px;
  font-size: clamp(20px, 2vw, 40px);
  font-weight: 800;
  line-height: 1.5;
}

@media (width >= 769px) {
  .management__pros-description {
    padding-inline: 40px 0;
  }
}

@media (width >= 1351px) {
  .management__pros-description {
    padding-block: 80px 0;
    padding-inline: 80px 0;
  }
}

.management__pros-wrap {
  grid-gap: 40px;
  grid-template-columns: 1fr;
  padding-block: 60px 50px;
  display: grid;
}

@media (width >= 769px) {
  .management__pros-wrap {
    grid-column-gap: 30px;
    grid-row-gap: 50px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 1351px) {
  .management__pros-wrap {
    grid-column-gap: 30px;
    grid-row-gap: 70px;
    padding-block: 80px 70px;
  }
}

.management__pros-item {
  flex-direction: column;
  padding-inline: 28px;
  display: flex;
}

@media (width >= 769px) {
  .management__pros-item:nth-child(odd) {
    padding-inline: 40px 0;
  }
}

@media (width >= 1351px) {
  .management__pros-item:nth-child(odd) {
    padding-inline: 80px 0;
  }
}

@media (width >= 769px) {
  .management__pros-item:nth-child(2n) {
    padding-inline: 0 40px;
  }
}

@media (width >= 1351px) {
  .management__pros-item:nth-child(2n) {
    padding-inline: 0 80px;
  }
}

.management__pros-number {
  color: #fff3;
  font-size: 40px;
  font-weight: 700;
}

.management__pros-number:first-letter {
  margin-left: -.1em;
}

@media (width >= 769px) {
  .management__pros-number {
    font-size: 52px;
  }
}

@media (width >= 1351px) {
  .management__pros-number {
    font-size: 96px;
    line-height: 100%;
  }
}

.management__pros-header {
  text-transform: uppercase;
  padding-block: 12px;
  font-size: 18px;
  font-weight: 700;
}

@media (width >= 769px) {
  .management__pros-header {
    font-size: 20px;
    line-height: 150%;
  }
}

@media (width >= 1351px) {
  .management__pros-header {
    font-size: 24px;
  }
}

.management__pros-info {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__pros-info {
    font-size: 18px;
  }
}

@media (width >= 1351px) {
  .management__pros-info {
    font-size: 24px;
  }
}

.management__fast-return {
  background: var(--color-red);
  border-radius: 48px;
  position: relative;
  overflow: hidden;
}

.management__fast-return:after {
  content: "";
  opacity: .15;
  background: url("lion-logo.6704ec11.svg") 0 0 / cover no-repeat;
  block-size: 98%;
  inline-size: 90%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: -165px;
}

@media (width >= 769px) {
  .management__fast-return:after {
    block-size: 147%;
    inline-size: 140px;
    inset-block-start: -64px;
    inset-inline-end: 0;
  }
}

.management__fast-return__wrap {
  flex-direction: column;
  padding-block: 40px;
  padding-inline: 30px;
  display: flex;
}

@media (width >= 769px) {
  .management__fast-return__wrap {
    flex-direction: row;
    align-items: center;
    padding-inline: 40px;
  }
}

@media (width >= 1351px) {
  .management__fast-return__wrap {
    padding-inline: 80px;
  }
}

.management__fast-return-span {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__fast-return-span {
    font-size: 20px;
  }
}

@media (width >= 1351px) {
  .management__fast-return-span {
    font-size: 28px;
  }
}

.management__fast-return-info {
  padding-block: 16px 20px;
  font-size: 16px;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__fast-return-info {
    margin-right: 20px;
    padding-block: 16px 0;
    font-size: 18px;
  }
}

@media (width >= 1351px) {
  .management__fast-return-info {
    font-size: 24px;
  }
}

.management__fast-return-btn {
  background: var(--color-white);
  color: var(--color-red);
  text-align: center;
  cursor: pointer;
  z-index: 10;
  border: none;
  border-radius: 48px;
  padding-block: 16px;
  padding-inline: 40px;
  font-size: clamp(1rem, .52vw + .81rem, 1.25rem);
  font-weight: 700;
}

.management__fast-return-btn:hover {
  animation: 1s infinite pulse;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.management__before-after {
  padding-block-start: 90px;
}

@media (width >= 769px) {
  .management__before-after {
    padding-block-start: 100px;
  }
}

@media (width >= 1351px) {
  .management__before-after {
    padding-block-start: 120px;
  }
}

.management__before-after-header {
  color: var(--color-white);
  padding-block-end: 28px;
  font-size: 20px;
  font-weight: 800;
}

@media (width >= 769px) {
  .management__before-after-header {
    padding-block-end: 40px;
    font-size: 28px;
    font-weight: 900;
    line-height: 150%;
  }
}

@media (width >= 1351px) {
  .management__before-after-header {
    padding-block-end: 40px;
    padding-inline-start: 90px;
    font-size: 40px;
  }
}

.management__before-after-card {
  grid-gap: 20px;
  grid-template-columns: 1fr;
  display: grid;
}

@media (width >= 768px) {
  .management__before-after-card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 1351px) {
  .management__before-after-card {
    grid-gap: 30px;
  }
}

.management__before-after-card-wrap {
  background: var(--color-white);
  box-sizing: border-box;
  border-radius: 48px;
}

.management__before-after-card-content {
  margin-inline-start: 0;
  padding-block: 28px;
  padding-inline: 28px;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__before-after-card-content {
    margin-block-end: 0;
    padding-block: 40px;
    padding-inline: 40px;
  }
}

@media (width >= 1351px) {
  .management__before-after-card-content {
    padding-inline: 80px;
  }
}

.management__before-after-case-tag {
  color: var(--color-red-1);
  background: #bb264914;
  border-radius: 40px;
  inline-size: 132px;
  padding-block: 14px;
  padding-inline: 40px;
  font-size: 16px;
  font-weight: 700;
}

@media (width >= 769px) {
  .management__before-after-case-tag {
    inline-size: 138px;
    padding-block: 13px;
    font-size: 18px;
  }
}

@media (width >= 1351px) {
  .management__before-after-case-tag {
    inline-size: 159px;
    font-size: 24px;
    line-height: 150%;
  }
}

.management__before-after-case {
  flex-direction: column;
  padding-block-start: 40px;
  display: flex;
}

@media (width <= 768px) {
  .management__before-after-case {
    padding-block-start: 20px;
  }
}

.management__before-after-title {
  padding-block-end: 8px;
  font-size: 18px;
  font-weight: 700;
}

@media (width >= 769px) {
  .management__before-after-title {
    font-size: 20px;
    line-height: 150%;
  }
}

@media (width >= 1351px) {
  .management__before-after-title {
    padding-block-end: 8px;
    font-size: 28px;
  }
}

.management__before-after-reason {
  font-size: 16px;
  line-height: 24px;
}

@media (width >= 769px) {
  .management__before-after-reason {
    font-size: 18px;
    line-height: 150%;
  }
}

@media (width >= 1351px) {
  .management__before-after-reason {
    font-size: 24px;
    line-height: 36px;
  }
}

.management__before-after-reason-list {
  padding-left: 28px;
  font-size: 16px;
  line-height: 150%;
  list-style: disc;
}

@media (width >= 769px) {
  .management__before-after-reason-list {
    font-size: 18px;
  }
}

@media (width >= 1351px) {
  .management__before-after-reason-list {
    font-size: 24px;
  }
}

.management__taxes-container {
  grid-gap: 20px;
  grid-template-columns: 1fr;
  display: grid;
}

@media (width >= 1351px) {
  .management__taxes-container {
    grid-gap: 30px;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 1fr;
  }
}

.management__taxes {
  padding-block-start: 90px;
}

@media (width >= 769px) {
  .management__taxes {
    padding-block-start: 100px;
  }
}

@media (width >= 1351px) {
  .management__taxes {
    padding-block-start: 120px;
  }

  .management__taxes-grid-1 {
    grid-area: 1 / 1 / 2 / 2;
  }

  .management__taxes-grid-2 {
    grid-area: 1 / 2 / 3 / 3;
  }
}

.management__taxes-grid-3 {
  order: 1;
}

@media (width >= 1351px) {
  .management__taxes-grid-3 {
    order: unset;
    grid-area: 2 / 1 / 4 / 2;
  }
}

.management__taxes-grid-4 {
  order: 2;
}

@media (width >= 1351px) {
  .management__taxes-grid-4 {
    order: unset;
    grid-area: 3 / 2 / 4 / 3;
  }
}

.management__taxes-header {
  color: var(--color-white);
  padding-block-end: 28px;
  font-size: 18px;
  font-weight: 800;
}

@media (width >= 769px) {
  .management__taxes-header {
    padding-block-end: 40px;
    font-size: 28px;
  }
}

@media (width >= 1351px) {
  .management__taxes-header {
    padding-inline-start: 80px;
    font-size: 40px;
  }
}

.management__taxes-card-wrap {
  background: var(--color-white);
  border-radius: 48px;
}

.management__taxes-content {
  padding-block: 28px 40px;
  padding-inline: 28px;
  display: block;
}

@media (width >= 769px) {
  .management__taxes-content {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    padding-block: 40px;
    padding-inline: 40px;
    display: grid;
  }
}

@media (width >= 1351px) {
  .management__taxes-content {
    flex-direction: column;
    block-size: 100%;
    padding-block: 40px;
    padding-inline: 80px;
    display: flex;
  }
}

.management__taxes-tag {
  color: var(--color-red-1);
  grid-column: span 2;
  margin-bottom: 24px;
  font-size: clamp(1.25rem, 1.55vw + .69rem, 2rem);
  font-weight: 800;
  display: block;
}

.management__taxes-mobile-grid {
  grid-template-rows: 89px;
}

@media (width <= 768px) {
  .management__taxes-mobile-grid {
    grid-template-rows: unset;
  }
}

@media (width >= 1351px) {
  .management__taxes-mobile-grid {
    grid-template-rows: unset;
  }
}

.management__taxes-card-title {
  margin-bottom: 20px;
  font-size: clamp(1rem, 1.03vw + .63rem, 1.5rem);
  font-weight: 700;
  line-height: 150%;
}

.management__taxes-list {
  font-size: 16px;
  list-style: none;
  position: relative;
}

.management__taxes-list:before {
  content: "";
  background: #000;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 9px;
  left: 8px;
}

@media (width >= 769px) {
  .management__taxes-list:before {
    top: 11px;
    left: 2px;
  }
}

@media (width >= 1351px) {
  .management__taxes-list:before {
    top: 17px;
    left: 8px;
  }
}

@media (width >= 769px) {
  .management__taxes-list {
    font-size: 18px;
    line-height: 150%;
  }
}

@media (width >= 1351px) {
  .management__taxes-list {
    text-indent: 0;
    font-size: 24px;
  }
}

.management__taxes-piece .management__taxes-list {
  padding-inline-start: 16px;
}

@media (width <= 768px) {
  .management__taxes-piece .management__taxes-list {
    padding-inline-start: 26px;
  }
}

@media (width >= 1351px) {
  .management__taxes-piece .management__taxes-list {
    padding-inline-start: 28px;
  }
}

.management__taxes-list-padding {
  margin-block-start: 24px;
}

.management__taxes-line {
  padding-block-end: 19px;
  position: relative;
}

.management__taxes-line:after {
  content: "";
  background: url("line.0cb81607.svg");
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
}

@media (width >= 769px) {
  .management__taxes-line:after {
    background: url("line-vert.8d60e1ed.svg");
    width: 2px;
    height: calc(100% - 40px);
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

@media (width >= 1351px) {
  .management__taxes-line:after {
    margin: initial;
    background: url("line.0cb81607.svg");
    width: 100%;
    height: 2px;
    top: auto;
    bottom: 0;
  }
}

@media (width >= 769px) {
  .management__taxes-line {
    padding-block-end: 0;
    padding-inline-end: 24px;
  }
}

@media (width >= 1351px) {
  .management__taxes-line {
    padding-block-end: 24px;
  }
}

.management__taxes-gap {
  margin-block: 16px 28px;
  padding-inline-start: 0;
}

@media (width >= 769px) {
  .management__taxes-gap {
    margin-block: 40px;
    padding-inline-start: 20px;
  }
}

@media (width >= 1351px) {
  .management__taxes-gap {
    margin-block: 24px;
    padding-inline-start: 0;
  }
}

.management__piece-top {
  margin-block-start: 24px;
}

.management__taxes-piece {
  display: grid;
}

.management__taxes-piece:last-child {
  margin-block-start: 24px;
}

.management__taxes-services {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__taxes-services {
    font-size: 18px;
  }
}

@media (width >= 1351px) {
  .management__taxes-services {
    font-size: 24px;
  }
}

.management__taxes-price {
  font-size: 28px;
  font-weight: 800;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__taxes-price {
    margin-top: 4px;
    font-size: 36px;
  }
}

@media (width >= 1351px) {
  .management__taxes-price {
    margin-top: 8px;
    font-size: 3rem;
  }
}

.management__taxes-asterisk {
  color: var(--color-red);
}

.management__taxes-footnote {
  color: var(--color-gray-1);
  font-size: 14px;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__taxes-footnote {
    font-size: 16px;
  }
}

@media (width >= 1351px) {
  .management__taxes-footnote {
    font-size: 24px;
  }
}

.management__taxes-btn {
  background: var(--color-red);
  color: var(--color-white);
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 48px;
  grid-column: span 2;
  inline-size: 100%;
  padding-block: 16px;
  padding-inline: 40px;
  font-size: 20px;
  font-weight: 700;
  display: block;
}

.management__taxes-btn:hover {
  background: var(--color-red-5);
  transform: scale(1.05);
  box-shadow: 4px 4px 10px #5e2e2e80;
}

@media (width >= 769px) {
  .management__taxes-btn {
    margin-block-start: 28px;
    line-height: 150%;
  }
}

.management__taxes-btn-wrapper {
  flex-grow: 1;
  align-items: end;
  display: flex;
}

.management__taxes-demo {
  background: var(--color-red);
  color: var(--color-white);
  border-radius: 48px;
  position: relative;
  overflow: hidden;
}

.management__taxes-demo:after {
  content: "";
  opacity: .15;
  background: url("lion-logo.6704ec11.svg") 0 0 / cover no-repeat;
  block-size: 87%;
  inline-size: 269px;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: -142px;
  overflow: hidden;
}

@media (width >= 769px) {
  .management__taxes-demo:after {
    block-size: 132%;
    inset-block-start: -17px;
    inset-inline-end: -73px;
  }
}

@media (width >= 1351px) {
  .management__taxes-demo:after {
    block-size: 92%;
    inline-size: 100%;
    inset-block-start: 163px;
    inset-inline-end: -324px;
  }
}

.management__taxes-demo-inner {
  flex-direction: column;
  display: flex;
}

.management__taxes-demo-wrap {
  padding-block: 40px;
  padding-inline: 28px;
}

@media (width >= 769px) {
  .management__taxes-demo-wrap {
    padding-inline: 40px;
  }
}

@media (width >= 1351px) {
  .management__taxes-demo-wrap {
    flex-direction: column;
    justify-content: space-between;
    block-size: 100%;
    padding-block: 40px;
    padding-inline: 80px;
    display: flex;
  }
}

.management__taxes-demo-tag {
  background: var(--color-red-4);
  border-radius: 40px;
  inline-size: 187px;
  padding-block: 12px;
  padding-inline: 40px;
  font-size: 16px;
  font-weight: 700;
}

@media (width >= 769px) {
  .management__taxes-demo-tag {
    inline-size: 200px;
    font-size: 18px;
    line-height: 150%;
  }
}

.management__taxes-demo-title {
  text-transform: uppercase;
  padding-block-start: 28px;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__taxes-demo-title {
    padding-block-start: 40px;
    font-size: 24px;
  }
}

@media (width >= 1351px) {
  .management__taxes-demo-title {
    padding-block-start: 24px;
    font-size: 1.875rem;
  }
}

.management__taxes-demo-info {
  padding-block: 24px 28px;
  line-height: 150%;
}

@media (width >= 769px) {
  .management__taxes-demo-info {
    font-size: 18px;
  }
}

@media (width >= 1351px) {
  .management__taxes-demo-info {
    font-size: 24px;
    line-height: 36px;
  }
}

.management__taxes-demo-btn {
  text-align: center;
  background: var(--color-white);
  color: var(--color-red);
  cursor: pointer;
  z-index: 10;
  border: none;
  border-radius: 40px;
  inline-size: 100%;
  padding-block: 16px;
  padding-inline: 37px;
  font-size: 20px;
  font-weight: 700;
}

.management__taxes-demo-btn:hover {
  animation: 1s infinite pulse;
}

@media (width >= 1351px) {
  .management__taxes-demo-btn {
    margin-block-start: 40%;
  }
}

.management__swiper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (width <= 768px) {
  .management__swiper {
    margin-inline: 20px;
  }
}

.management__swiper-wrapper {
  inline-size: 100%;
  overflow: hidden;
}

.management__swiper-swipe {
  justify-content: space-between;
  align-items: center;
  padding-block: 90px 28px;
  padding-inline: 0;
  display: flex;
}

@media (width >= 769px) {
  .management__swiper-swipe {
    padding-block: 90px 43px;
  }
}

@media (width >= 1351px) {
  .management__swiper-swipe {
    padding-inline: 80px;
  }
}

.management__swiper-title {
  color: var(--color-white);
  font-size: 20px;
}

@media (width >= 769px) {
  .management__swiper-title {
    font-size: 28px;
    font-weight: 900;
    line-height: 150%;
  }
}

@media (width >= 1351px) {
  .management__swiper-title {
    font-size: 40px;
  }
}

.management__swiper-buttons {
  flex-shrink: 0;
  display: flex;
}

.management__swiper-btn {
  background: var(--color-red-2);
  cursor: pointer;
  border: none;
  border-radius: 50%;
  block-size: 48px;
  inline-size: 48px;
  margin-inline-start: 28px;
  position: relative;
}

.management__swiper-btn:first-child {
  margin-inline-start: 0;
}

.management__swiper-btn:hover {
  background: var(--color-red);
}

.management__swiper-btn.swiper-button-disabled {
  cursor: default;
  opacity: .5;
  background: #524040;
}

.management__swiper-btn-arrow {
  border: 2px solid var(--color-white);
  border-top: none;
  border-right: none;
  block-size: 16px;
  inline-size: 16px;
  margin-block: 50px;
  margin-inline: 50px;
  position: absolute;
  inset-block-start: -33px;
  inset-inline-start: -36px;
  transform: rotate(-135deg);
}

.management__swiper-btn-arrow-left {
  inset-inline-start: -31px;
  transform: rotate(44deg);
}

.management__swiper-slide {
  background: var(--color-white);
  opacity: .5;
  cursor: default;
  border-radius: 48px;
  max-inline-size: 1370px;
}

.management__swiper-slide.swiper-slide-active {
  opacity: 1;
}

@media (width >= 769px) {
  .management__swiper-slide {
    width: calc(100% - 40px);
    margin-left: 7px;
  }
}

@media (width >= 1351px) {
  .management__swiper-slide {
    width: calc(100% - 300px);
    margin-left: 0;
  }
}

.management__swiper-content {
  padding-block: 40px;
  padding-inline: 40px;
}

@media (width >= 769px) {
  .management__swiper-content {
    grid-template-rows: 1fr;
    grid-template-columns: 34% 66%;
    display: grid;
  }
}

@media (width >= 1351px) {
  .management__swiper-content {
    grid-template-rows: 1fr;
    grid-template-columns: 300px 2fr;
    padding-block: 80px;
    padding-inline: 80px;
    display: grid;
  }
}

.management__swiper-content-wrap {
  border-bottom: 1px solid var(--color-red);
  display: grid;
}

@media (width >= 769px) {
  .management__swiper-content-wrap {
    border-bottom: 0;
    border-right: 1px solid #bb26497a;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 20px;
    display: flex;
  }
}

@media (width >= 1351px) {
  .management__swiper-content-wrap {
    padding-right: 30px;
  }
}

.management__swiper-content-img {
  margin-bottom: 40px;
}

.management__swiper-company {
  padding-block-start: 20px;
  font-size: 16px;
  font-weight: 800;
}

@media (width >= 769px) {
  .management__swiper-company {
    padding-block-start: 0;
    font-size: 18px;
  }
}

@media (width >= 1351px) {
  .management__swiper-company {
    margin-top: 40px;
    font-size: 24px;
  }
}

.management__swiper-client {
  color: var(--color-gray-1);
  padding-block: 14px 20px;
  font-size: 16px;
}

@media (width >= 769px) {
  .management__swiper-client {
    padding-block: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
  }
}

@media (width >= 1351px) {
  .management__swiper-client {
    margin-top: 8px;
    font-size: 24px;
  }
}

.management__swiper-review {
  block-size: 269px;
  padding-block-start: 20px;
  font-size: 16px;
  line-height: 150%;
  overflow: hidden;
}

.management__swiper-review_expanded {
  block-size: auto !important;
}

@media (width >= 769px) {
  .management__swiper-review {
    block-size: 331px;
    padding-block-start: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
  }
}

@media (width >= 1351px) {
  .management__swiper-review {
    padding-block-start: 0;
    font-size: 24px;
    line-height: 36px;
  }
}

@media (width >= 769px) {
  .management__swiper-review, .management__swiper-expand {
    padding-inline-start: 30px;
  }
}

@media (width >= 1351px) {
  .management__swiper-review, .management__swiper-expand {
    padding-inline-start: 30px;
  }
}

.management__swiper-expand-btn {
  cursor: pointer;
  background: none;
  border: none;
}

.management__swiper-expand {
  color: var(--color-red);
  font-size: 16px;
  font-weight: 700;
}

.management__swiper-expand:active {
  color: var(--color-red);
}

.management__swiper-expand:hover {
  color: var(--color-black);
}

@media (width >= 769px) {
  .management__swiper-expand {
    font-size: 18px;
    font-weight: 400;
  }
}

@media (width >= 1351px) {
  .management__swiper-expand {
    font-size: 24px;
  }
}

.management__swiper-btn-next {
  justify-content: center;
  margin-block-start: 40px;
  display: flex;
}

.management__swiper-next {
  background: var(--color-red);
  cursor: pointer;
  border: none;
  border-radius: 50%;
  block-size: 16px;
  inline-size: 16px;
  margin-inline-start: 16px;
}

.management__swiper-next:first-child {
  margin-inline-start: 0;
}

.management__swiper-next:hover {
  background: var(--color-red-2);
}

.management__examples-content {
  padding-block: 28px 40px;
  padding-inline: 28px;
}

@media (width >= 769px) {
  .management__examples-content {
    padding-block: 40px;
    padding-inline: 40px;
  }
}

@media (width >= 1351px) {
  .management__examples-content {
    padding-block: 40px;
    padding-inline: 80px;
  }
}

.management__examples-tag {
  color: var(--color-red-1);
  background: #bb264914;
  border-radius: 40px;
  padding-block: 14px;
  padding-inline: 40px;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
}

@media (width >= 769px) {
  .management__examples-tag {
    padding-block: 13px;
    font-size: 18px;
  }
}

@media (width >= 1351px) {
  .management__examples-tag {
    font-size: 24px;
    line-height: 150%;
  }
}

.management__examples-title {
  margin-block: 40px 12px;
  font-size: clamp(1.25rem, 1.03vw + .88rem, 1.75rem);
  font-weight: 700;
}

.management__examples-description {
  margin-bottom: 12px;
  font-size: clamp(1.13rem, .78vw + .85rem, 1.5rem);
  font-weight: 400;
}

.management__examples-toggler {
  color: var(--color-red-1);
  background: none;
  border: none;
  font-size: clamp(1.13rem, .26vw + 1.03rem, 1.25rem);
  font-weight: 700;
}

.management__examples-toggler-icon {
  color: var(--color-red-1);
  margin-left: 7px;
  transition: all .6s;
}

.management__examples-toggler--active .management__examples-toggler-icon {
  transform: rotateX(180deg);
}

.management__examples-collapse {
  max-height: 0;
  margin-top: 30px;
  transition: max-height .6s ease-out;
  display: block;
  overflow: hidden;
}

.management__examples-collapse--active {
  max-height: 9999px;
  display: block;
}

.management__examples-list {
  font-size: clamp(1.13rem, .78vw + .85rem, 1.5rem);
  font-weight: 400;
  list-style: disc;
}

.management__examples-list li {
  margin-bottom: 12px;
  margin-left: 30px;
  font-weight: 700;
}

.management__examples-list p {
  margin-bottom: 24px;
}

.management__contacts {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.management__contacts-container {
  height: 100%;
  position: relative;
}

.management__contacts-map {
  z-index: 1;
  filter: grayscale();
  width: 100%;
  height: 500px;
  max-height: 80vh;
}

@media (width >= 769px) {
  .management__contacts-map {
    height: 800px;
  }
}

.management__contacts-wrap {
  position: relative;
}

.management__contacts-card {
  z-index: 2;
  background: #25272e;
  border-radius: 48px;
  padding-block: 28px 40px;
  padding-inline: 28px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  overflow: hidden;
  transform: translateY(50%);
}

@media (width <= 768px) {
  .management__contacts-card {
    background: none;
    position: static;
    transform: none;
  }
}

@media (width >= 1351px) {
  .management__contacts-card {
    padding-block: 40px;
    padding-inline: 80px;
  }
}

.management__contacts-card:before {
  content: "";
  filter: blur(350px);
  z-index: -1;
  background: #bb26497a;
  border-radius: 260px;
  width: 100%;
  height: 200%;
  animation-name: color-blinking-red;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  display: none;
  position: absolute;
  bottom: -100%;
  left: 75%;
  transform: rotateZ(25deg)translateX(-50%)translateY(50%);
}

@media (width >= 769px) {
  .management__contacts-card:before {
    display: block;
  }
}

.management__contacts-card:after {
  content: "";
  filter: blur(350px);
  z-index: -1;
  background: #283f8e66;
  border-radius: 260px;
  width: 100%;
  height: 300%;
  animation-name: color-blinking-blue;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  display: none;
  position: absolute;
  top: -50%;
  right: -50%;
  transform: rotateZ(-25deg);
}

@media (width >= 769px) {
  .management__contacts-card:after {
    display: block;
  }
}

.management__contacts-title {
  color: #fff;
  margin-bottom: 30px;
  font-size: clamp(1.25rem, 2.58vw + .32rem, 2.5rem);
}

.management__contacts-image {
  opacity: .1;
  height: 150%;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)translateX(50%)scaleX(1.1);
}

@media (width >= 769px) {
  .management__contacts-image {
    display: block;
  }
}

.management__footer {
  z-index: 20;
  color: var(--color-white);
  padding-block-start: 90px;
}

@media (width >= 769px) {
  .management__footer {
    padding-block-start: 100px;
  }
}

@media (width >= 1351px) {
  .management__footer {
    padding-block-start: 110px;
    padding-inline: 80px;
  }
}

.management__footer-title {
  color: var(--color-white);
  padding-block-end: 10px;
  font-size: clamp(1.13rem, 1.29vw + .66rem, 1.75rem);
}

.management__footer-wrap {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  display: block;
}

@media (width >= 768px) {
  .management__footer-wrap {
    grid-gap: 30px;
    display: grid;
  }
}

.management__footer-mobile, .management__footer-list, .management__footer-license {
  display: flex;
}

@media (width <= 768px) {
  .management__footer-mobile, .management__footer-list, .management__footer-license {
    display: block;
  }
}

.management__footer-list {
  justify-content: space-between;
  inline-size: 100%;
  padding-inline-start: 80px;
}

@media (width <= 768px) {
  .management__footer-list {
    padding-inline-start: 0;
  }
}

.management__footer-mobile img {
  inline-size: 80px;
}

@media (width >= 769px) {
  .management__footer-mobile {
    padding-block-end: 60px;
  }
}

.management__footer-contact-piece {
  padding-block-end: 40px;
}

.management__footer-contact-piece:last-child {
  padding-block-end: 0;
}

@media (width >= 769px) {
  .management__footer-contact-piece {
    padding-block-end: 0;
  }
}

.management__footer-contact {
  font-size: clamp(1rem, 1.03vw + .63rem, 1.5rem);
}

.management__footer-contact:hover {
  color: var(--color-red);
}

.management__footer-address {
  color: var(--color-white);
}

.management__footer-icons {
  margin-block-start: 15px;
}

@media (width >= 1351px) {
  .management__footer-icons {
    margin-block-start: 30px;
  }
}

.management__footer-icon {
  padding-inline-end: 23px;
  display: inline-block;
}

.management__footer-icon:last-child {
  padding-inline-end: 0;
}

.management__footer-icon:hover {
  opacity: .6;
}

@media (width >= 769px) {
  .management__footer-icon {
    padding-inline-end: 34px;
  }
}

.management__footer-tg, .management__footer-whatsapp {
  inline-size: 40px;
}

@media (width <= 768px) {
  .management__footer-tg, .management__footer-whatsapp {
    inline-size: 23px;
  }
}

@media (width >= 1351px) {
  .management__footer-tg, .management__footer-whatsapp {
    inline-size: 40px;
  }
}

.management__footer-links {
  text-align: center;
}

.management__footer-item {
  padding-block-start: 40px;
  padding-inline: 0;
}

.management__footer-item:last-child {
  padding-block-end: 60px;
  padding-inline: 0;
}

@media (width >= 769px) {
  .management__footer-item:last-child {
    padding-block-end: 0;
  }

  .management__footer-item {
    align-items: center;
    padding-block-start: 0;
    padding-inline: 0;
    display: flex;
  }
}

.management__footer-item-link:hover {
  color: var(--color-red);
}

@media (width >= 769px) {
  .management__footer-item-link {
    font-size: 14px;
  }
}

@media (width >= 1351px) {
  .management__footer-item-link {
    font-size: 16px;
  }
}

.management__footer-license {
  text-align: center;
  opacity: .8;
  flex-direction: column;
  justify-content: space-between;
  margin-block-start: 0;
  padding-block-end: 33px;
  display: flex;
}

@media (width >= 769px) {
  .management__footer-license {
    flex-direction: row;
    padding-block-end: 0;
  }
}

.management__footer-company {
  opacity: .8;
  flex-direction: column;
  padding-block-end: 28px;
  display: flex;
}

.management__footer-company span {
  padding-block-end: 4px;
}

@media (width <= 768px) {
  .management__footer-company {
    align-items: center;
  }
}

.management__footer-copyright {
  padding-block-end: 20px;
}

.management__footer-privacy:hover {
  color: var(--color-red);
}

.management__reviews-container {
  padding-inline: 30px;
}

.slogans {
  color: #fff;
  margin-top: 15vh;
}

@media (width <= 768px) {
  .slogans {
    flex-direction: column;
    gap: 60px;
    display: flex;
  }
}

.slogans__row {
  gap: 30px;
  display: grid;
}

@media (width >= 768px) {
  .slogans__row {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media (width >= 1351px) {
  .slogans__row {
    gap: 30px;
  }
}

.slogans__row:last-child {
  flex-direction: column-reverse;
  display: flex;
}

@media (width >= 768px) {
  .slogans__row:last-child {
    flex-direction: row;
    margin-top: -30px;
    display: grid;
  }
}

@media (width >= 993px) {
  .slogans__row:last-child {
    margin-top: -60px;
  }
}

@media (width >= 1351px) {
  .slogans__row:last-child {
    margin-top: -6%;
  }
}

.slogans__col {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.slogans__title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  display: block;
}

@media (width >= 993px) {
  .slogans__title {
    font-size: 20px;
  }
}

@media (width >= 1351px) {
  .slogans__title {
    font-size: 24px;
  }
}

.slogans__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

@media (width >= 993px) {
  .slogans__description {
    font-size: 20px;
  }
}

@media (width >= 1351px) {
  .slogans__description {
    font-size: 24px;
  }
}

.slogans__list {
  margin-top: 20px;
  padding-left: 20px;
  font-size: 18px;
  line-height: 1.75;
  list-style: disc;
}

@media (width >= 993px) {
  .slogans__list {
    font-size: 20px;
  }
}

@media (width >= 1351px) {
  .slogans__list {
    font-size: 24px;
  }
}

.slogans__image {
  border: 4px solid;
  border-radius: 48px;
  width: 100%;
}

.slogans__image--1 {
  border-color: var(--color-red-1);
  box-shadow: 0 4px 60px #bb264966, inset 12px 12px 20px #bb26494d;
}

.slogans__image--2 {
  border-color: var(--color-blue);
  box-shadow: 0 4px 60px #283f8e66, inset 12px 12px 20px #283f8e4d;
}

.container {
  inline-size: 100%;
  max-inline-size: 1678px;
  padding-inline: 20px;
  position: relative;
}

@media (width >= 1351px) {
  .container {
    padding-inline: 150px;
  }
}

@media (width <= 768px) {
  .container_without-padding-in-mobile {
    padding-inline: 0;
  }
}

.politics {
  background: var(--color-white);
  color: var(--color-black);
  border-radius: 48px;
  margin-top: 150px;
  margin-bottom: 40px;
  padding-block: 40px;
  padding-inline: 40px;
}

.politics h1 {
  margin-top: 16px;
  margin-bottom: .75em;
  font-size: 2em;
}

.politics h2 {
  margin-block: .5em;
  font-size: 1.75em;
}

.politics h3 {
  margin-block: .3em;
  font-size: 1.5em;
}

.politics p {
  margin-block: 1em;
  font-size: 1.1em;
}

.politics a, .politics__back {
  color: var(--color-red);
}

@media (width <= 768px) {
  .politics {
    border-radius: 40px;
    margin-top: 100px;
    padding-block: 20px;
    padding-inline: 20px;
    font-size: 13px;
  }
}

.table-wrapper {
  overflow-x: auto;
}

.header-nav {
  background: #3a2f50;
  background: var(--gradient-menu);
  opacity: 0;
  inline-size: 100%;
  padding-block: 84px 20px;
  padding-inline: 25px 35px;
  transition: all .3s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

@media (width >= 993px) {
  .header-nav {
    opacity: 1;
    background: none;
    justify-content: end;
    align-items: center;
    inline-size: 100%;
    max-inline-size: 1150px;
    padding-block: 20px;
    padding-inline: 30px 0;
    display: flex;
    position: relative;
    transform: translateY(0);
  }
}

@media (width >= 993px) and (width <= 1350px) {
  .header-nav {
    padding-inline: 15px 0;
  }
}

@media (width >= 1351px) {
  .header-nav {
    padding-inline: 75px 120px;
  }
}

@media (width <= 992px) {
  .header-nav_active {
    opacity: 1;
    background-color: #00000080;
    display: block;
    transform: translateX(0%);
    box-shadow: 0 0 0 100vmax #000000b3;
  }
}

.header-nav__menu {
  -webkit-backdrop-filter: blur(5px);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  inline-size: 100%;
  padding-inline-start: 20px;
  display: flex;
}

@media (width >= 993px) {
  .header-nav__menu {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline-start: 0;
  }
}

@media (width >= 1351px) {
  .header-nav__menu {
    gap: 20px;
  }
}

.header-nav__link {
  color: var(--color-white);
  block-size: 100%;
  padding-block: 14px;
  font-size: 16px;
  line-height: 150%;
  display: inline-block;
}

.header-nav__link:hover {
  color: var(--color-red);
}

@media (width >= 993px) and (width <= 1350px) {
  .header-nav__link {
    font-size: clamp(.88rem, .56vw + .53rem, 1rem);
  }
}

@media (width >= 1351px) {
  .header-nav__link {
    font-size: 16px;
  }
}

.header-nav__item:first-child {
  padding-block-start: 4px;
}

@media (width >= 993px) {
  .header-nav__item:first-child {
    padding-block-start: 0;
  }
}

.header-nav__item:last-child {
  padding-block-end: 4px;
}

@media (width >= 993px) {
  .header-nav__item:last-child {
    padding-block-end: 0;
  }
}

.header-buttons {
  z-index: 10;
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.header-buttons__menu-burger {
  z-index: 10;
  padding-block: 10px;
  padding-inline: 10px;
  display: block;
}

@media (width >= 993px) {
  .header-buttons__menu-burger {
    display: none;
  }
}

.header-buttons__burger-btn {
  background: none;
  border: none;
  display: flex;
}

.header-buttons__burger-btn img {
  width: 44px;
  height: 44px;
}

.header-buttons__burger-btn img:last-child {
  display: none;
}

.modal {
  z-index: 12;
  background: #000000b3;
  justify-content: center;
  align-items: center;
  block-size: 100%;
  inline-size: 100%;
  padding-block: 50px;
  display: none;
  position: fixed;
  top: 0;
  overflow-y: auto;
}

.modal__tariff-selector-wrapper {
  position: relative;
}

.modal__tariff-selector-icon {
  position: absolute;
  top: 18px;
  right: 22px;
}

.modal__tariff-selector-icon.active {
  transform: rotate(-90deg);
}

@media (width >= 769px) {
  .modal__tariff-selector-icon {
    top: 17px;
    right: 20px;
  }
}

@media (width >= 1351px) {
  .modal__tariff-selector-icon {
    top: 24px;
    right: 20px;
  }
}

.modal__description {
  letter-spacing: .2px;
  padding-block-start: 24px;
}

@media (width >= 769px) {
  .modal__description {
    padding-block-start: 0;
  }
}

@media (width >= 1351px) {
  .modal__description {
    line-height: 150%;
  }
}

.modal__thanks-screen {
  flex-direction: column;
  max-width: 650px;
  max-height: 650px;
  display: none;
}

.modal__thanks-screen.active {
  display: flex;
}

.modal_visible {
  display: grid;
}

.modal__line-or {
  color: #f8f8fd47;
  font-weight: bold;
}

@media (width <= 768px) {
  .modal__line-or {
    padding-inline: 5px;
  }
}

.modal__close-icon {
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  top: -35px;
  right: 0;
}

@media (width <= 1350px) {
  .modal__close-icon {
    background: #666;
    border-radius: 50%;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    block-size: 40px;
    inline-size: 40px;
    display: flex;
    top: 10px;
    right: 10px;
  }
}

.modal__close-icon img {
  block-size: 30px;
  inline-size: 30px;
}

.modal__privacy-wrap {
  align-items: center;
  margin-block: 20px 30px;
  display: flex;
}

@media (width <= 900px) {
  .modal__privacy-wrap {
    text-align: left;
    inline-size: 100%;
  }
}

.modal__privacy {
  color: var(--color-black);
  padding-inline-start: 10px;
  font-size: 16px;
}

.modal__privacy_red {
  color: var(--color-red);
}

.modal__body {
  background: var(--gradient-modal);
  color: var(--color-white);
  border-radius: 48px;
  position: relative;
}

@media (width >= 1351px) {
  .modal__body {
    background: var(--gradient-dropdown);
  }
}

.modal__close-btn {
  color: var(--color-white);
  cursor: pointer;
  background: none;
  border: none;
  font-size: 3rem;
  position: absolute;
  inset-block-start: 11px;
  inset-inline-end: 31px;
}

.modal__close-btn:hover {
  color: var(--color-red);
}

.modal__tg-text:hover {
  color: var(--color-tg);
}

.modal__wrap {
  max-width: 1220px;
  padding-block: 30px;
  padding-inline: 30px;
  display: none;
}

.modal__wrap.active {
  display: grid;
}

@media (width >= 769px) {
  .modal__wrap {
    padding-block: 60px;
    padding-inline: 60px;
  }
}

@media (width >= 1351px) {
  .modal__wrap {
    grid-gap: 30px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    padding-block: 50px;
    padding-inline: 63px;
  }
}

.modal__exit-wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width >= 1351px) {
  .modal__exit-wrap {
    grid-column: span 2;
  }
}

.modal__title {
  text-transform: uppercase;
  font-size: 20px;
}

@media (width >= 769px) {
  .modal__title {
    line-height: 150%;
  }
}

@media (width >= 1351px) {
  .modal__title {
    max-width: 830px;
    font-size: 32px;
  }
}

.modal__info, .modal__desktop-only {
  font-size: 18px;
}

@media (width <= 768px) {
  .modal__info, .modal__desktop-only {
    font-size: 16px;
  }
}

@media (width >= 1351px) {
  .modal__info, .modal__desktop-only {
    font-size: 24px;
  }
}

.modal__desktop-only {
  display: none;
}

@media (width <= 768px) {
  .modal__desktop-only {
    display: none;
  }
}

@media (width >= 1351px) {
  .modal__desktop-only {
    display: block;
  }
}

.modal__info {
  flex-direction: column;
  padding-block-end: 30px;
  display: flex;
}

@media (width <= 768px) {
  .modal__info {
    padding-block-end: 30px;
  }
}

@media (width >= 1351px) {
  .modal__info {
    padding-block-end: 60px;
  }
}

.modal__server {
  color: var(--color-red);
  padding-block-start: 10px;
}

.modal__desktop-only, .modal__tg-text, .modal__server {
  font-weight: 700;
}

.modal__mobile-reverse {
  flex-direction: column-reverse;
  display: flex;
}

@media (width >= 1351px) {
  .modal__mobile-reverse {
    flex-direction: column;
  }
}

.modal__tg-text {
  justify-content: center;
  align-items: center;
  padding-block-start: 24px;
  display: flex;
}

@media (width >= 769px) {
  .modal__tg-text {
    flex-direction: row;
    margin-block-start: 24px;
    padding-block-start: 0;
  }
}

@media (width >= 1351px) {
  .modal__tg-text {
    margin-block-start: 64px;
    padding-block-start: 0;
  }
}

.modal__icon {
  inline-size: 40px;
  margin-inline-end: 20px;
}

@media (width >= 769px) {
  .modal__icon {
    margin-block-end: 10px;
    margin-inline-end: 0;
  }
}

@media (width >= 1351px) {
  .modal__icon {
    margin-block-end: 0;
    margin-inline-end: 20px;
  }
}

.modal__contact-tg {
  align-items: center;
  display: grid;
}

.modal__line {
  background: #f8f8fd47;
  block-size: 2px;
}

.modal__lines {
  text-align: center;
  opacity: .5;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr .2fr 1fr;
  align-items: center;
  padding-block-start: 20px;
  display: grid;
}

@media (width <= 768px) {
  .modal__lines {
    padding-block-start: 20px;
  }
}

@media (width >= 1351px) {
  .modal__lines {
    display: none;
  }
}

.modal__tg-hide {
  display: none;
}

@media (width <= 768px) {
  .modal__tg-hide {
    display: none;
  }
}

@media (width >= 1351px) {
  .modal__tg-hide {
    display: flex;
  }
}

.modal__tg-desktop {
  justify-content: center;
  display: flex;
}

@media (width >= 769px) {
  .modal__tg-desktop {
    justify-content: center;
  }
}

@media (width >= 1351px) {
  .modal__tg-desktop {
    display: none;
  }
}

.modal__form {
  flex-direction: column;
  display: flex;
}

.modal__tariff-close {
  border-right: 2px solid var(--color-white);
  border-bottom: 2px solid var(--color-white);
  block-size: 10px;
  inline-size: 10px;
  display: inline-block;
  position: absolute;
  inset-block-start: 20px;
  inset-inline-end: 28px;
  transform: rotate(44deg);
}

.modal__selector-selected-option {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: .875rem;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.modal__selector-selected-option.modal__input .selector-selected-option_active {
  border: 2px solid #bb2649;
}

.modal__selector-dropdown {
  background: var(--color-gray-2);
  border-radius: 20px;
  width: 100%;
  padding-block: 10px;
  display: none;
  position: absolute;
  top: 47px;
}

.modal__selector-dropdown.active {
  display: block;
}

@media (width >= 769px) {
  .modal__selector-dropdown {
    padding-block: 25px;
    top: 62px;
  }
}

.modal__selector-option {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding-block: 16px;
  padding-inline: 20px;
  font-size: 16px;
  display: flex;
}

.modal__selector-option span {
  flex-grow: 1;
  padding-inline-start: 5px;
  font-weight: 700;
}

.modal__selector-option_selected {
  background: #bb264947;
}

.modal__selector-option_selected .modal__selector-option-icon {
  display: block;
}

.modal__selector-option:hover {
  background: #e3466a47;
}

.modal__selector-option-icon {
  width: 20px;
  height: auto;
  display: none;
}

.modal__tariff-options {
  border: 1px solid var(--color-white);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  inline-size: 100%;
  display: none;
  position: absolute;
  inset-block-start: 67px;
  inset-inline-start: 0;
}

.modal__tariff-option {
  border-bottom: 1px solid var(--color-white);
  cursor: pointer;
  padding-block: 16px;
  padding-inline: 20px;
}

.modal__tariff-option:last-child {
  border-bottom: 0;
}

.modal__tariff-option:hover {
  background: var(--color-red);
}

.modal__tariff-uppercase {
  text-transform: uppercase;
  font-weight: 700;
}

.modal__input {
  border: 1px solid var(--color-white);
  color: var(--color-white);
  background: #ffffff30;
  border-radius: 28px;
  outline: none;
  inline-size: 100%;
  margin-block-end: 20px;
  padding-block: 10px;
  padding-inline: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
}

.modal__input::-webkit-input-placeholder {
  color: var(--color-white);
  font-weight: 400;
}

@media (width >= 1351px) {
  .modal__input_textarea {
    min-width: 533px;
    max-width: 533px;
    min-height: 142px;
    max-height: 142px;
  }

  .modal__input {
    margin-block-end: 30px;
    padding-block: 16px;
    font-size: 16px;
  }
}

.modal__btn {
  color: var(--color-white);
  background: var(--color-red);
  cursor: pointer;
  border: none;
  border-radius: 48px;
  padding-block: 12px;
  padding-inline: 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  transition: transform .3s;
  position: relative;
}

.modal__btn:hover {
  transform: scale(1.03);
  box-shadow: 4px 4px 10px #5e2e2e80;
}

@media (width >= 1351px) {
  .modal__btn {
    padding-block: 16px;
    font-size: 16px;
  }
}

.modal__btn--loading {
  color: #7b1930;
  background: #7b1930;
}

.modal__btn--loading:hover {
  transform: none;
}

.modal__btn--loading:after {
  content: "";
  border: 4px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin: auto;
  animation: 1s infinite button-loading-spinner;
  position: absolute;
  inset: 0;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.modal__small {
  text-align: center;
  color: #f6d1da;
  margin-top: 10px;
  font-size: .75rem;
}

.modal__popup {
  background: var(--gradient-dropdown);
  color: var(--color-white);
  border-radius: 48px;
}

.modal__popup-wrap {
  padding-block: 60px;
  padding-inline: 60px;
}

.modal__popup-right {
  text-align-last: right;
}

.modal__popup-checkmark {
  inline-size: 85px;
  max-inline-size: 85px;
  padding-block-end: 57px;
  padding-inline-start: 28px;
}

.modal__popup-ty {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 900;
}

@media (width >= 769px) {
  .modal__popup-ty {
    font-size: 32px;
  }
}

.modal__popup-warn {
  padding-block: 28px;
  font-size: 1.1rem;
  line-height: 150%;
}

@media (width >= 769px) {
  .modal__popup-warn {
    font-size: 24px;
  }
}

.modal__popup-gl {
  font-size: 24px;
  font-weight: 700;
}

@media (width <= 768px) {
  .modal__popup-gl {
    font-size: 1.2rem;
  }
}

.modal__popup-btn {
  color: var(--color-white);
  background: var(--color-red);
  cursor: pointer;
  border: none;
  border-radius: 48px;
  inline-size: 100%;
  margin-block-start: 20px;
  padding-block: 16px;
  padding-inline: 40px;
  font-size: 16px;
  font-weight: 700;
}

.modal__popup-btn:hover {
  transform: scale(1.05);
  box-shadow: 4px 4px 10px #5e2e2e80;
}

@media (width >= 769px) {
  .modal__popup-btn {
    margin-block-start: 28px;
  }
}

.shake-it {
  animation: .4s ease-in-out infinite horizontal-shaking;
}

.swiper-pagination-bullet {
  background: var(--color-red);
  block-size: 16px;
  inline-size: 16px;
  margin-left: 16px !important;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}

.gradient-container {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.gradient-floater {
  z-index: 19;
  width: 260vw;
  height: 260vw;
  position: relative;
}

@media (width >= 769px) {
  .gradient-floater {
    width: 92vw;
    height: 99vw;
  }
}

.gradient-floater_red-block {
  background: radial-gradient(circle, #8f0a297a -31%, #283f8e00 45%);
  animation: 5s linear infinite forwards floating-red;
}

.gradient-floater_blue-block {
  z-index: 1;
  opacity: .8;
  background: radial-gradient(circle, #253e81 -31%, #283f8e00 45%);
  animation: 5s linear infinite forwards floating-blue;
  position: absolute;
}

.gradient-floater:nth-child(13) {
  background: radial-gradient(circle, #482581 -31%, #283f8e00 45%);
}

@keyframes floating-red {
  0% {
    transform: translate(0);
  }

  25% {
    transform: translate(180px);
  }

  50% {
    transform: translate(180px, 180px);
  }

  75% {
    transform: translate(0, 180px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes floating-blue {
  0% {
    transform: translate(0);
  }

  25% {
    transform: translate(0, 150px);
  }

  50% {
    transform: translate(100px, 150px);
  }

  75% {
    transform: translate(0, 150px);
  }

  100% {
    transform: translate(0);
  }
}

.gradient-floater_red-block:first-child {
  animation-duration: 8s;
  inset-block-start: -2%;
  inset-inline-end: 35%;
}

@media (width >= 769px) {
  .gradient-floater_red-block:first-child {
    inset-block-start: -4%;
    inset-inline-end: -34%;
  }

  .gradient-floater_red-block:first-child .gradient-floater_blue-block {
    inset-block-start: 12%;
  }
}

.gradient-floater_red-block:nth-child(2) {
  animation-duration: 12s;
  inset-block-end: 0;
  inset-inline-start: 0;
}

@media (width >= 769px) {
  .gradient-floater_red-block:nth-child(2) {
    inset-block-end: 2%;
    inset-inline-start: -46%;
  }
}

.gradient-floater_red-block:nth-child(2) .gradient-floater_blue-block {
  inset-block-start: 12%;
}

@media (width >= 769px) {
  .gradient-floater_red-block:nth-child(2) .gradient-floater_blue-block {
    inset-block-start: 12%;
  }
}

.gradient-floater_red-block:nth-child(3) {
  animation-duration: 10s;
  inset-block-end: -64px;
  inset-inline-start: -43%;
}

@media (width >= 769px) {
  .gradient-floater_red-block:nth-child(3) {
    inset-block-end: 6%;
    inset-inline-start: 39%;
  }

  .gradient-floater_red-block:nth-child(3) .gradient-floater_blue-block {
    inset-block-start: -40%;
  }
}

.gradient-floater_red-block:nth-child(4) {
  animation-duration: 14s;
  inset-block-end: -70px;
  inset-inline-start: -49%;
}

.gradient-floater_red-block:nth-child(5) {
  animation-duration: 9s;
  inset-block-end: -44px;
  inset-inline-start: 42%;
}

.gradient-floater_red-block:nth-child(6) {
  animation-duration: 13s;
  position: absolute;
  inset-block-end: -84px;
  inset-inline-end: -43%;
}

.gradient-floater_red-block:nth-child(7) {
  animation-duration: 11s;
  position: absolute;
  inset-block-end: -2%;
  inset-inline-start: -123%;
}

@media (width >= 769px) {
  .gradient-floater_red-block:nth-child(7) {
    inset-block-end: -64px;
    inset-inline-start: -43%;
  }
}

.gradient-floater_red-block:nth-child(7) .gradient-floater_blue-block {
  inset-block-start: -25%;
}

.gradient-floater_red-block:nth-child(8) {
  animation-duration: 8s;
  position: absolute;
  inset-block-start: -2%;
  inset-inline-start: 35%;
}

@media (width >= 769px) {
  .gradient-floater_red-block:nth-child(8) {
    inset-block-start: -4%;
    inset-inline-start: -34%;
  }

  .gradient-floater_red-block:nth-child(8) .gradient-floater_blue-block {
    inset-block-start: 12%;
  }
}

@keyframes color-blinking-red {
  0% {
    background: #bb26492e;
  }

  50% {
    background: #bb264961;
  }

  80% {
    background: #bb26498c;
  }

  100% {
    background: #bb26497a;
  }
}

@keyframes color-blinking-blue {
  0% {
    background: #283f8e7a;
  }

  50% {
    background: #283f8e61;
  }

  80% {
    background: #283f8e80;
  }

  100% {
    background: #283f8e7a;
  }
}

body {
  position: relative;
}

::-webkit-scrollbar {
  width: 15px;
  padding: 0 3px;
}

::-webkit-scrollbar-track {
  background: var(--color-red-3);
}

::-webkit-scrollbar-thumb {
  background: var(--color-red);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-red-1);
}
/*# sourceMappingURL=index.e1636917.css.map */
