@import "./breakpoints.scss";
@import "./responsive-font-size.scss";

$header-block-size: 128px;
$header-block-size-mobile: 84px;

.management {
  background: var(--bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @include media('>xl') {
    padding-inline: 0;
  }

  &__header-right-side {
    display: flex;
    inline-size: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  &__header-icons{
    display: flex;
    gap: 20px;
    padding-inline: 30px;
    @include media('<xs') {
      padding-inline: 15px;
    }
    
    @include media('>lg', '<xl') {
      padding-inline: 15px;
    }

  }
  
  &__header-icon{
    img{
      width: 30px;
      height: 30px;
    }
  }

  &__burger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 10px;
    padding-inline: 20px 6px;
    position: fixed;
    inline-size: 100%;
    background: #25272ea1;
    backdrop-filter: blur(2px);
    z-index: 11;

    @include media('>md') {
      block-size: 120px;
      padding-inline: 20px 10px;
    }

    @include media('>xl') {
      padding-inline: 80px;
    }
  }

  &__burger-wrap {
    inline-size: 100%;
    background: var(--gradient-menu);
  }

  &__logo {
    inline-size: 48px;
    block-size: 59px;

    @include media('>md') {
      inline-size: 80px;
      block-size: 100px;
    }
  }

  &__logo-link {
    z-index: 10;
  }

  &__button-wrapper {
    padding-inline-end: 14px;
    z-index: 120;

    @include media('>md') {
      padding-inline-end: 0;
    }
    @include media('<xs') {
      padding-inline-end: 0;
    }
  }

  &__button {
    background: var(--color-red);
    border-radius: 48px;
    border: none;
    color: var(--color-white);
    padding-block: 8px;
    padding-inline: 20px;
    font-weight: 700;
    font-size: 0.875rem;
    cursor: pointer;
    text-wrap: nowrap;
    z-index: 10;
    line-height: 150%;

    &:hover {
      @include media('>xl') {
        background: var(--color-red-5);
        transition-duration: .1s;
      }
    }

    @include media('>md') {
      padding-block: 16px;
      padding-inline: 40px;
      font-size: 16px;
      margin-inline-end: 50px;
    }

    @include media('>lg') {
      margin-inline-end: 0;
    }
  }

  &__button-full{
    @include media('<xs') {
      display: none;
    }
  }

  /* Burger */

  &__burger-container {
    display: inline-block;
    cursor: pointer;
    position: fixed;
    inset-inline-end: 20px;

    @include media('>xl') {
      display: none;
    }
  }

  &__burger-line1,
  &__burger-line2,
  &__burger-line3 {
    inline-size: 36px;
    block-size: 5px;
    background: var(--color-white);
    margin-block: 6px;
    margin-inline: 0;
    transition: 0.4s;
    border-radius: 5px;
  }
  
  /* Rotate the first bar */
  .change &__burger-line1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
    transform: rotate(-45deg) translate(-9px, 6px) ;
    border-radius: 3px;
  }
  
  /* Fade out the second bar */
  .change &__burger-line2 {
    opacity: 0;
  }
  
  /* Rotate the last bar */
  .change &__burger-line3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
    border-radius: 3px;
  }

  /* Main block */
  &__try-out {
    padding-block-start: 184px;
    text-align: center;

    @include media('>md') {
      padding-block-start: 336px;
    }

    @include media('>xl') {
      padding-block-start: 300px;
    }
  }

  &__title-main {
    font-size: 40px;
    line-height: 150%;
    font-weight: 900;
    color: var(--color-white);
    text-transform: uppercase;
    padding-block-end: 16px;

    @include media('>md') {
      font-size: 52px;
      padding-block-end: 28px;
    }

    @include media('>xl') {
      font-size: 68px;
      padding-block-end: 20px;
    }
  }

  &__subtitle-main {
    font-size: 18px;
    font-weight: 800;
    color: var(--color-white);
    padding-block-end: 40px;
    line-height: 150%;

    @include media('>md') {
      font-size: 28px;
      padding-block-end: 24px;
    }

    @include media('>xl') {
      font-size: 40px;
      padding-block-end: 30px;
    }
  }

  &__paragraph-main {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-white);
    padding-block-end: 40px;
    line-height: 150%;

    @include media('>md') {
      font-size: 20px;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__button-main {
    border: none;
    cursor: pointer;
    color: white;
    background: var(--color-red);
    border-radius: 48px;
    padding-inline: 65px;
    padding-block: 16px;
    font-weight: 700;
    font-size: 20px;

    
    @include media('<xs') {
      font-size: 16px;
    }

    @include media('>md') {
      padding-inline: 40px;
    }

    @include media('>xl') {
      &:hover {
        background: var(--color-red-5);
        transition-duration: .1s;
      }
    }
  }

  /* Benefits */

  // &__benefits {
  //   color: var(--color-white);
  //   padding-block-start: 140px;
  //   max-inline-size: 1210px;

  //   @include media('<md') {
  //     padding-block-start: 130px;
  //   }
 
  //   @include media('>xl') {
  //     padding-block-start: 210px;
  //   }
  // }

  // &__benefits-wrap {
  //   display: flex;
  //   justify-content: space-between;
  //   flex-direction: column;

  //   @include media('>md') {
  //     flex-direction: row;
  //     align-items: center;
  //   }

  //   @include media('>xl') {
  //     flex-direction: row;
  //   }
  // }

  // &__benefits-item-bold {
  //   text-transform: uppercase;
  //   font-size: 18px;
  //   font-weight: 700;
  //   line-height: 27px;
  //   padding-block-end: 20px;

  //   @include media('>md') {
  //     width: calc(50% - 10px);
  //     font-size: 20px;
  //     line-height: 150%;
  //     padding-block-end: 0;
  //   }

  //   @include media('>xl') {
  //     font-size: 24px;
  //     width: 36%;
  //   }
  // }
  
  // &__benefits-item {
  //   color: var(--color-white);
  //   line-height: 150%;
  //   font-size: 18px;
  //   font-weight: 400;

  //   @include media('>md') {
  //     width: calc(50% - 10px)
  //   }

  //   @include media('>xl') {
  //     font-size: 24px;
  //     width: 51%;
  //   }
  // }
  
  /* Assets cards */

  &__assets {
    color: var(--color-white);
    padding-block-start: 110px;

    @include media('>md') {
      padding-block-start: 100px;
    }

    @include media('>xl') {
      padding-block-start: 120px;
    }
  }

  &__assets-header {
    font-size: 20px;
    font-weight: 700;
    inline-size: 100%;
    padding-block-end: 28px;

    @include media('>md') {
      display: block;
      max-width: 65%;
      font-weight: 900;
      line-height: 150%;
      font-size: 28px;
    }

    @include media('>xl') {
      font-size: 40px;
      max-inline-size: 1070px;
      padding-block-end: 40px;
      padding-inline-start: 96px;
    }
  }

  /* Header. Mobile only */

  &__assets-mobile-only {
    font-size: 20px;
    font-weight: 800;
    padding-block-end: 28px;
    text-transform: uppercase;

    @include media('>md') {
      display: none;
    }
  }

  &__assets-cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @include media('>=md') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media('>lg') {
      grid-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__assets-card-item {
    background: var(--color-white);
    border-radius: 48px;
    color: var(--color-black);

    &:last-child{
      @include media('>md', '<lg'){
        grid-column: span 2;
      }
    }
  }

  &__assets-card-icon {
    inline-size: 76px;
  }

  &__assets-card-description {
    font-size: 18px;
    line-height: 150%;
    font-weight: 700;
    padding-block: 29px 20px;

    @include media('>md') {
      font-size: 20px;
    }

    @include media('>xl') {
      font-size: 28px;
    }
  }

  &__assets-card-info-wrap {
    display: flex;
    flex-direction: column;
  }

  &__assets-card-item-wrap {
    padding-inline: 28px;
    padding-block: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include media('>md') {
      padding-inline: 40px;
      justify-content: space-between;
    }

    @include media('>xl') {
      min-height: 400px;
      align-items: unset;
      padding-inline: 40px;
    }
  }

  &__assets-card-btn {
    border: none;
    background: var(--color-red-3);
    padding-inline: 20px;
    padding-block: 16px;
    color: var(--color-red-1);
    font-size: 20px;
    font-weight: 700;
    border-radius: 48px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: var(--color-red-2);
    }
  }

  /* Pros */

  &__pros {
    background: var(--color-violet-1);
    border-radius: 48px;
    color: var(--color-white);
    margin-block-start: 100px;

    @include media('<md') {
      margin-block-start: 90px;
    }

    @include media('>xl') {
      margin-block-start: 120px;
    }
  }

  &__pros-description{
    font-size: clamp(20px, 2vw, 40px);
    font-weight: 800;
    line-height: 1.5;
    padding-block: 60px 0;

    padding-inline: 28px;
    @include media('>md') {
      padding-inline: 40px 0;
    }
    @include media('>xl') {
      padding-inline: 80px 0;
      padding-block: 80px 0;
    }

  }

  &__pros-wrap {
    display: grid;
    grid-gap: 40px;
    padding-block: 60px 50px;
    grid-template-columns: 1fr;

    @include media('>md') {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 50px;
    }

    @include media('>xl') {
      padding-block: 80px 70px;
      grid-column-gap: 30px;
      grid-row-gap: 70px;
    }
  }

  &__pros-item {
    padding-inline: 28px;
    display: flex;
    flex-direction: column;

    &:nth-child(odd) {
      @include media('>md') {
        padding-inline: 40px 0;
      }
      @include media('>xl') {
        padding-inline: 80px 0;
      }
    }

    &:nth-child(even) {
      @include media('>md') {
        padding-inline: 0 40px;
      }
      @include media('>xl') {
        padding-inline: 0 80px;
      }
    }
  }

  &__pros-number {
    font-size: 40px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.2);

    &::first-letter {
      margin-left: -0.10em;
    }
    @include media('>md') {
      font-size: 52px;
    }

    @include media('>xl') {
      font-size: 96px;
      line-height: 100%;
    }
  }

  &__pros-header {
    font-size: 18px;
    font-weight: 700;
    padding-block: 12px;
    text-transform: uppercase;

    @include media('>md') {
      font-size: 20px;
      line-height: 150%;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__pros-info {
    font-weight: 400;
    line-height: 150%;
    font-size: 16px;

    @include media('>md') {
      font-size: 18px;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__fast-return {
    background: var(--color-red);
    border-radius: 48px;
    position: relative;
    overflow: hidden;

    &:after {
      background: url('/images/lion-logo.svg') no-repeat;
      content: '';
      position: absolute;
      opacity: .15;
      inset-block-start: 0;
      inset-inline-end: -165px;
      block-size: 98%;
      inline-size: 90%;
      background-size: cover;

      @include media('>md') {
        inset-inline-end: 0;
        inline-size: 120px;
        block-size: 120%;
        inset-block-start: -38px;
      }

      @include media('>md') {
        block-size: 147%;
        inline-size: 140px;
        inset-block-start: -64px;
        inset-inline-end: 0;
      }
    }
  }

  &__fast-return__wrap {
    padding-block: 40px;
    padding-inline: 30px;
    display: flex;
    flex-direction: column;

    @include media('>md') {
      flex-direction: row;
      align-items: center;
      padding-inline: 40px;
    }

    @include media('>xl') {
      padding-inline: 80px;
    }
  }

  &__fast-return-span {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 150%;

    @include media('>md') {
      font-size: 20px;
    }

    @include media('>xl') {
      font-size: 28px;
    }
  }

  &__fast-return-info {
    font-size: 16px;
    padding-block: 16px 20px;
    line-height: 150%;

    @include media('>md') {
      margin-right: 20px;
      font-size: 18px;
      padding-block: 16px 0;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__fast-return-btn {
    background: var(--color-white);
    border: none;
    color: var(--color-red);
    font-weight: 700;
    font-size: #{fluid(16px, 20px, $mediaXs, $mediaXl)};
    border-radius: 48px;
    padding-block: 16px;
    padding-inline: 40px;
    text-align: center;
    cursor: pointer;
    z-index: 10;

    &:hover {
      animation: pulse 1s infinite;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  /* Before and After cards */

  &__before-after {
    padding-block-start: 90px;

    @include media('>md') {
      padding-block-start: 100px;
    }

    @include media('>xl') {
      padding-block-start: 120px;
    }
  }

  &__before-after-header {
    font-weight: 800;
    color: var(--color-white);
    // text-transform: uppercase;
    font-size: 20px;
    padding-block-end: 28px;

    @include media('>md') {
      padding-block-end: 40px;
      font-size: 28px;
      line-height: 150%;
      font-weight: 900;
    }

    @include media('>xl') {
      font-size: 40px;
      padding-block-end: 40px;
      padding-inline-start: 90px;
    }
  }

  &__before-after-card {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;

    @include media('>=md') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media('>xl') {
      grid-gap: 30px;
    }
  }

  &__before-after-card-wrap {
    background: var(--color-white);
    border-radius: 48px;
    box-sizing: border-box;
  }

  &__before-after-card-content {
    line-height: 150%;
    padding-block: 28px;
    padding-inline: 28px;
    margin-inline-start: 0;

    @include media('>md') {
      padding-block: 40px;
      padding-inline: 40px;
      margin-block-end: 0;
    }

    @include media('>xl') {
      padding-inline: 80px;
    }
  }

  &__before-after-case-tag {
    color: var(--color-red-1);
    background: rgba(187, 38, 73, 0.08);
    padding-inline: 40px;
    border-radius: 40px;
    font-weight: 700;
    font-size: 16px;
    inline-size: 132px;
    padding-block: 14px;

    @include media('>md') {
      font-size: 18px;
      inline-size: 138px;
      padding-block: 13px;
    }

    @include media('>xl') {
      font-size: 24px;
      inline-size: 159px;
      line-height: 150%;
    }
  }

  &__before-after-case {
    display: flex;
    flex-direction: column;
    padding-block-start: 40px;

    @include media('<md') {
      padding-block-start: 20px;
    }
  }

  &__before-after-title {
    font-weight: 700;
    font-size: 18px;
    padding-block-end: 8px;

    @include media('>md') {
      font-size: 20px;
      line-height: 150%;
    }

    @include media('>xl') {
      font-size: 28px;
      padding-block-end: 8px;
    }
  }

  &__before-after-reason {
    line-height: 24px;
    font-size: 16px;

    @include media('>md') {
      font-size: 18px;
      line-height: 150%;
    }

    @include media('>xl') {
      font-size: 24px;
      line-height: 36px;
    }
  }
  &__before-after-reason-list {
    line-height: 24px;
    font-size: 16px;
    line-height: 150%;

    @include media('>md') {
      font-size: 18px;
    }

    @include media('>xl') {
      font-size: 24px;
    }

    padding-left: 28px;
    list-style: disc;
  }
  /* Taxes */

  &__taxes-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @include media('>xl') {
      grid-template-rows: auto auto auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
    }
  }

  &__taxes {
    padding-block-start: 90px;

    @include media('>md') {
      padding-block-start: 100px;
    }

    @include media('>xl') {
      padding-block-start: 120px;
    }
  }

  &__taxes-grid-1 {
    @include media('>xl') {
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  &__taxes-grid-2 {
    @include media('>xl') {
      grid-area: 1 / 2 / 3 / 3;
    }
  }

  &__taxes-grid-3 {
    order: 1;
    @include media('>xl') {
      grid-area: 2 / 1 / 4 / 2;
      order: unset;
    }
  }

  &__taxes-grid-4 {
    order: 2;
    @include media('>xl') {
      grid-area: 3 / 2 / 4 / 3;
      order: unset;
    }
  }

  &__taxes-header {
    color: var(--color-white);
    // text-transform: uppercase;
    font-weight: 800;
    padding-block-end: 28px;
    font-size: 18px;

    @include media('>md') {
      font-size: 28px;
      padding-block-end: 40px;
    }

    @include media('>xl') {
      font-size: 40px;
      padding-inline-start: 80px;
    }
  }

  &__taxes-card-wrap {
    background: var(--color-white);
    border-radius: 48px;
  }

  &__taxes-content {
    padding-block: 28px 40px;
    display: block;
    padding-inline: 28px;

    @include media('>md') {
      padding-block: 40px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      display: grid;
      padding-inline: 40px;
    }

    @include media('>xl') {
      padding-block: 40px;
      padding-inline: 80px;
      display: flex;
      flex-direction: column;
      block-size: 100%;
    }
  }

  &__taxes-tag {
    color: var(--color-red-1);
    // background: var(--color-blue-1);
    // border-radius: 40px;
    // padding-block: 12px;
    // padding-inline: 40px;
    // font-weight: 700;
    font-weight: 800;
    grid-column: span 2;
    display: block;
    // font-size: 16px;
    font-size: #{fluid(20px, 32px, $mediaXs, $mediaXl)};
    margin-bottom: 24px;
    // inline-size: 145px;
    // text-wrap: nowrap;

    // @include media('>md') {
    //   inline-size: 159px;
    //   font-size: 18px;
    //   line-height: 150%;
    //   text-align: center;
    // }

    // @include media('>xl') {
    //   font-size: 24px;
    //   inline-size: 175px;
    // }
  }

  &__taxes-mobile-grid {
    grid-template-rows: 89px;

    @include media('<md') {
      grid-template-rows: unset;
    }

    @include media('>xl') {
      grid-template-rows: unset;
    }
  }

  &__taxes-card-title {
    // padding-block: 28px 16px;
    font-weight: 700;
    // font-size: 20px;
    font-size: #{fluid(16px, 24px, $mediaXs, $mediaXl)};
    line-height: 150%;
    // text-transform: uppercase;
    margin-bottom: 20px;
    // @include media('>md') {
    //   padding-block: 40px 24px;
    //   font-size: 24px;
    // }

    // @include media('>xl') {
    //   font-size: 1.875rem;
    // }
  }

  &__taxes-list {
    font-size: 16px;
    list-style: none;
    position: relative;

    &:before {
      content: "";
      background: #000;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      position: absolute;
      top: 9px;
      left: 8px;

      @include media('>md') {
        top: 11px;
        left: 2px;
      }

      @include media('>xl') {
        top: 17px;
        left: 8px;
      }
    }

    @include media('>md') {
      font-size: 18px;
      line-height: 150%;
    }

    @include media('>xl') {
      font-size: 24px;
      text-indent: 0;
    }
  }

  &__taxes-piece &__taxes-list {
    padding-inline-start: 16px;

    @include media('<md') {
      padding-inline-start: 26px;
    }

    @include media('>xl') {
      padding-inline-start: 28px;
    }
  }

  &__taxes-list-padding {
    margin-block-start: 24px;
  }

  &__taxes-line {
    position: relative;
    padding-block-end: 19px;

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      background: url('/images/line.svg');
      bottom: 0;

      @include media('>md') {
        background: url('/images/line-vert.svg');
        width: 2px;
        height: calc(100% - 40px);
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }

      @include media('>xl') {
        width: 100%;
        height: 2px;
        background: url('/images/line.svg');
        bottom: 0;
        margin: initial;
        top: auto;
      }
    }

    @include media('>md') {
      padding-block-end: 0;
      padding-inline-end: 24px;
    }

    @include media('>xl') {
      padding-block-end: 24px;
    }
  }

  &__taxes-gap {
    margin-block: 16px 28px;
    padding-inline-start: 0;

    @include media('>md') {
      margin-block: 40px;
      padding-inline-start: 20px;
    }

    @include media('>xl') {
      padding-inline-start: 0;
      margin-block: 24px;
    }
  }

  &__piece-top {
    margin-block-start: 24px;
  }

  &__taxes-piece {
    display: grid;

    &:last-child {
      margin-block-start: 24px;
    }
  }

  &__taxes-services {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;

    @include media('>md') {
      font-size: 18px;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__taxes-price {
    font-weight: 800;
    font-size: 28px;
    line-height: 150%;

    @include media('>md') {
      font-size: 36px;
      margin-top: 4px;
    }

    @include media('>xl') {
      font-size: 3rem;
      margin-top: 8px;
    }
  }

  &__taxes-asterisk {
    color: var(--color-red);
  }

  &__taxes-footnote {
    font-size: 14px;
    line-height: 150%;
    color: var(--color-gray-1);

    @include media('>md') {
      font-size: 16px;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__taxes-btn {
    background: var(--color-red);
    color: var(--color-white);
    padding-block: 16px;
    padding-inline: 40px;
    inline-size: 100%;
    font-size: 20px;
    font-weight: 700;
    border-radius: 48px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none;
    grid-column: span 2;

    &:hover {
      transform: scale(1.05);
      box-shadow: 4px 4px 10px rgba(94, 46, 46, 0.5);
      background: var(--color-red-5);
    }

    @include media('>md') {
      line-height: 150%;
      margin-block-start: 28px;
    }
  }

  &__taxes-btn-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: end;
  }

  /* Demo ver card */

  &__taxes-demo {
    background: var(--color-red);
    border-radius: 48px;
    color: var(--color-white);
    position: relative;
    overflow: hidden;

    &:after {
      background: url('/images/lion-logo.svg') no-repeat;
      overflow: hidden;
      content: '';
      position: absolute;
      opacity: 0.15;
      background-size: cover;
      block-size: 87%;
      inline-size: 269px;
      inset-block-start: 0;
      inset-inline-end: -142px;

      @include media('>md') {
        block-size: 132%;
        inset-block-start: -17px;
        inset-inline-end: -73px;
      }

      @include media('>xl') {
        block-size: 92%;
        inline-size: 100%;
        inset-block-start: 163px;
        inset-inline-end: -324px;
      }
    }
  }

  &__taxes-demo-inner {
    display: flex;
    flex-direction: column;
  }

  &__taxes-demo-wrap {
    padding-block: 40px;
    padding-inline: 28px;

    @include media('>md') {
      padding-inline: 40px;
    }

    @include media('>xl') {
      padding-inline: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      block-size: 100%;
      padding-block: 40px;
    }
  }

  &__taxes-demo-tag {
    font-weight: 700;
    background: var(--color-red-4);
    padding-block: 12px;
    padding-inline: 40px;
    border-radius: 40px;
    font-size: 16px;
    inline-size: 187px;

    @include media('>md') {
      inline-size: 200px;
      font-size: 18px;
      line-height: 150%;
    }
  }

  &__taxes-demo-title {
    font-size: 20px;
    line-height: 150%;
    text-transform: uppercase;
    font-weight: 700;
    padding-block-start: 28px;

    @include media('>md') {
      font-size: 24px;
      padding-block-start: 40px;
    }

    @include media('>xl') {
      font-size: 1.875rem;
      padding-block-start: 24px;
    }
  }
  
  &__taxes-demo-info {
    line-height: 150%;
    padding-block: 24px 28px;

    @include media('>md') {
      font-size: 18px;
    }

    @include media('>xl') {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &__taxes-demo-btn {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    inline-size: 100%;
    background: var(--color-white);
    color: var(--color-red);
    padding-block: 16px;
    padding-inline: 37px;
    border-radius: 40px;
    border: none;
    cursor: pointer;
    z-index: 10;

    &:hover {
      animation: pulse 1s infinite;
    }

    @include media('>xl') {
      margin-block-start: 40%;
    }
  }

  /* Swiper */
  &__swiper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media('<md') {
      margin-inline: 20px;
    }
  }

  &__swiper-wrapper {
    inline-size: 100%;
    overflow: hidden;
  }

  &__swiper-swipe {
    padding-inline: 0;
    padding-block: 90px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media('>md') {
      padding-block: 90px 43px;
    }

    @include media('>xl') {
      padding-inline: 80px;
    }
  }

  &__swiper-title {
    color: var(--color-white);
    // text-transform: uppercase;
    font-size: 20px;

    @include media('>md') {
      font-size: 28px;
      font-weight: 900;
      line-height: 150%;
    }

    @include media('>xl') {
      font-size: 40px;
    }
  }
  &__swiper-buttons{
    display: flex;
    flex-shrink: 0;
  }
  &__swiper-btn {
    inline-size: 48px;
    block-size: 48px;
    background: var(--color-red-2);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin-inline-start: 28px;
    position: relative;

    &:first-child {
      margin-inline-start: 0;
    }

    &:hover {
      background: var(--color-red);
    }

    &.swiper-button-disabled {
      cursor: default;
      background: #524040;
      opacity: 0.5;
    }
  }
  
  &__swiper-btn-arrow {
    inline-size: 16px;
    block-size: 16px;
    margin-block: 50px;
    margin-inline: 50px;
    border: 2px solid var(--color-white);
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    position: absolute;
    inset-block-start: -33px;
    inset-inline-start: -36px;
  }

  &__swiper-btn-arrow-left {
    transform: rotate(44deg);
    inset-inline-start: -31px;
  }

  &__swiper-slide {
    background: var(--color-white);
    border-radius: 48px;
    max-inline-size: 1370px;
    opacity: 0.5;
    cursor: default;

    &.swiper-slide-active {
      opacity: 1;
    }

    @include media('>md') {
      width: calc(100% - 40px);
      margin-left: 7px;
    }

    @include media('>xl') {
      width: calc(100% - 300px);
      margin-left: 0;
    }
  }

  &__swiper-content {
    padding-block: 40px;
    padding-inline: 40px;

    @include media('>md') {
      grid-template-rows: 1fr;
      grid-template-columns: 34% 66%;
      display: grid;
    }

    @include media('>xl') {
      padding-block: 80px;
      padding-inline: 80px;
      grid-template-rows: 1fr;
      grid-template-columns: 300px 2fr;
      display: grid;
    }
  }

  &__swiper-content-wrap {
    display: grid;
    border-bottom: 1px solid var(--color-red);

    @include media('>md') {
      display: flex;
      border-bottom: 0;
      border-right: 1px solid rgba(187, 38, 73, 0.48);
      padding-right: 20px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    @include media('>xl') {
      padding-right: 30px;
    }
  }

  &__swiper-content-img {
    margin-bottom: 40px;
  }

  &__swiper-company {
    font-weight: 800;
    padding-block-start: 20px;
    font-size: 16px;

    @include media('>md') {
      padding-block-start: 0;
      font-size: 18px;
    }

    @include media('>xl') {
      font-size: 24px;
      margin-top: 40px;
    }
  }

  &__swiper-client {
    color: var(--color-gray-1);
    padding-block: 14px 20px;
    font-size: 16px;

    @include media('>md') {
      padding-block: 8px;
      font-size: 18px;
      line-height: 150%;
      font-weight: 500;
    }

    @include media('>xl') {
      font-size: 24px;
      margin-top: 8px;
    }
  }

  &__swiper-review {
    overflow: hidden;
    block-size: 269px;
    padding-block-start: 20px;
    font-size: 16px;
    line-height: 150%;

    &_expanded {
      block-size: auto !important;
    }

    @include media('>md') {
      block-size: 331px;
      font-size: 18px;
      line-height: 150%;
      font-weight: 400;
      padding-block-start: 0;
    }

    @include media('>xl') {
      font-size: 24px;
      padding-block-start: 0;
      line-height: 36px;
    }
  }

  &__swiper-review,
  &__swiper-expand {
    @include media('>md') {
      padding-inline-start: 30px;
    }
    @include media('>xl') {
      padding-inline-start: 30px;
    }
  }

  &__swiper-expand-btn {
    background: none;
    border: none;
    cursor: pointer;
  }

  &__swiper-expand {
    color: var(--color-red);
    font-weight: 700;
    font-size: 16px;

    &:active {
      color: var(--color-red);
    }

    &:hover {
      color: var(--color-black);
    }

    @include media('>md') {
      font-size: 18px;
      font-weight: 400;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__swiper-btn-next {
    display: flex;
    justify-content: center;
    margin-block-start: 40px;
  }

  &__swiper-next {
    background: var(--color-red);
    inline-size: 16px;
    block-size: 16px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin-inline-start: 16px;

    &:first-child {
      margin-inline-start: 0;
    }

    &:hover {
      background: var(--color-red-2);
    }
  }

  // Examples
  &__examples-content{
    padding-block: 28px 40px;
    padding-inline: 28px;
    @include media('>md') {
      padding-block: 40px;
      padding-inline: 40px;
    }
    @include media('>xl') {
      padding-block: 40px;
      padding-inline: 80px;
    }
  }
  &__examples-tag{
    color: var(--color-red-1);
    background: rgba(187, 38, 73, 0.08);
    padding-inline: 40px;
    border-radius: 40px;
    font-weight: 700;
    font-size: 16px;
    padding-block: 14px;
    display: inline-block;

    @include media('>md') {
      font-size: 18px;
      padding-block: 13px;
    }

    @include media('>xl') {
      font-size: 24px;
      line-height: 150%;
    }
  } 
  &__examples-title{
    font-size: #{fluid(20px, 28px, $mediaXs, $mediaXl)};
    font-weight: 700;
    margin-block: 40px 12px;
  }

  &__examples-description{
    font-size: #{fluid(18px, 24px, $mediaXs, $mediaXl)};
    font-weight: 400;
    margin-bottom: 12px;
  }

  &__examples-toggler{
    background: transparent;
    border: none;
    color: var(--color-red-1);
    font-size: 20px;
    font-size: #{fluid(18px, 20px, $mediaXs, $mediaXl)};
    font-weight: 700;
  }

  &__examples-toggler-icon{
    margin-left: 7px;
    color: var(--color-red-1);
    transition: .6s;
  }

  &__examples-toggler--active &__examples-toggler-icon{
    transform: rotateX(180deg);
  }

  &__examples-collapse{
    margin-top: 30px;
    display: block;

    max-height: 0;
    transition: max-height .6s ease-out;
    overflow: hidden;

    &--active{
      max-height: 9999px;
      display: block;
    }
  }

  &__examples-list{
    list-style: disc;
    font-weight: 400;
    font-size: #{fluid(18px, 24px, $mediaXs, $mediaXl)};

    li{
      font-weight: 700;
      margin-left: 30px;
      margin-bottom: 12px;
    }

    p{
      margin-bottom: 24px;
    }
  }
  
  // Contacts
  &__contacts{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__contacts-container{
    height: 100%;
    position: relative;
  }
  &__contacts-map{
    z-index: 1;
    filter: grayscale(1);
    width: 100%;
    height: 500px;
    max-height: 80vh;

    @include media('>md') {
      height: 800px;
    }
  }

  &__contacts-wrap{
   position: relative;
  }

  &__contacts-card{
    overflow: hidden;

    padding-block: 40px;
    padding-inline: 40px;

    position: absolute;
    top: 50%;
    transform: translateY(50%);
    left: 0;
    right: 0;

    z-index: 2;
    border-radius: 48px;
    background: rgba(37, 39, 46, 1);

    padding-block: 28px 40px;
    padding-inline: 28px;

    @include media('<md') {
      position: static;
      transform: none;
      background: none;
    }

    @include media('>xl') {
      padding-block: 40px;
      padding-inline: 80px;
    }
   
    &::before{
      content: '';
      position: absolute;
      background: rgba(187, 38, 73, 0.48);
      bottom: -100%;
      left: 75%;
      width: 100%;
      height: 200%;
      transform: rotateZ(25deg) translateX(-50%) translateY(50%);
      border-radius: 260px;
      filter: blur(350px);
      z-index: -1;
      display: none;
      
      animation-name: color-blinking-red;
      animation-duration: 10s;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      @include media('>md') {
        display: block;
      }
    }

    &::after{
      content: '';
      position: absolute;
      background: rgba(40, 63, 142, 0.4);
      top: -50%;
      right: -50%;
      width: 100%;
      height: 300%;
      transform: rotateZ(-25deg);
      border-radius: 260px;
      filter: blur(350px);
      z-index: -1;
      display: none;

      animation-name: color-blinking-blue;
      animation-duration: 10s;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      @include media('>md') {
        display: block;
      }
    }
  }
  
  &__contacts-title{
    font-size: #{fluid(20px, 40px, $mediaXs, $mediaXl)};
    color: #fff;
    margin-bottom: 30px;
  }

  &__contacts-image{
    position: absolute;
    right: 0;
    height: 150%;
    top: 50%;
    transform: translateY(-50%) translateX(50%) scaleX(1.1);
    opacity: 0.1;
    display: none;

    @include media('>md') {
      display: block;
    }
  }

  /* Footer */
  &__footer {
    z-index: 20;
    color: var(--color-white);
    padding-block-start: 90px;

    @include media('>md') {
      padding-block-start: 100px;
    }

    @include media('>xl') {
      padding-inline: 80px;
      padding-block-start: 110px;
    }
  }
  
  &__footer-title {
    padding-block-end: 10px;
    color: var(--color-white);
    font-size: #{fluid(18px, 28px, $mediaXs, $mediaXl)};
  }

  &__footer-wrap {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    // padding-block: 28px 110px;
   
    display: block;

    @include media('>=md') {
      display: grid;
      grid-gap: 30px;
      // padding-block: 60px 120px;
    }

    @include media('>xl') {
      // padding-block: 40px 144px;
    }
  }

  &__footer-mobile,
  &__footer-list,
  &__footer-license {
    display: flex;

    @include media('<md') {
      display: block;
    }
  }

  &__footer-list {
    justify-content: space-between;
    inline-size: 100%;
    padding-inline-start: 80px;

    @include media('<md') {
      padding-inline-start: 0;
    }
  }

  &__footer-mobile {
    img {
      inline-size: 80px;
    }

    @include media('>md') {
      padding-block-end: 60px;
    }
  }

  &__footer-contact-piece {
    padding-block-end: 40px;

    &:last-child {
      padding-block-end: 0;
    }

    @include media('>md') {
      padding-block-end: 0;
    }
  }

  &__footer-contact {
    font-size: #{fluid(16px, 24px, $mediaXs, $mediaXl)};

    &:hover {
      color: var(--color-red);
    }

  }

  &__footer-address {
    color: var(--color-white);
  }

  &__footer-icons {
    margin-block-start: 15px;

    @include media('>xl') {
      margin-block-start: 30px;
    }
  }

  &__footer-icon {
    display: inline-block;
    padding-inline-end: 23px;

    &:last-child {
      padding-inline-end: 0;
    }

    &:hover {
      opacity: .6;
    }

    @include media('>md') {
      padding-inline-end: 34px;
    }
  } 

  &__footer-tg,
  &__footer-whatsapp {
    inline-size: 40px;

    @include media('<md') {
      inline-size: 23px;
    }

    @include media('>xl') {
      inline-size: 40px;
    }
  }

  &__footer-links {
    text-align: center;
  }

  &__footer-item {
    padding-block-start: 40px;
    padding-inline: 0;

    &:last-child {
      padding-block-end: 60px;
      padding-inline: 0;

      @include media('>md') {
        padding-block-end: 0;
      }
    }

    @include media('>md') {
      padding-inline: 80px 106px;
      padding-block-start: 0;
      display: flex;
      align-items: center;
    }
    
    @include media('>md') {
      padding-inline: 0;
    }
  }

  &__footer-item-link {
    &:hover {
      color: var(--color-red);
    }

    @include media('>md') {
      font-size: 14px;
    }

    @include media('>xl') {
      font-size: 16px;
    }
  }
  
  &__footer-license {
    display: flex;
    justify-content: space-between;
    text-align: center;
    opacity: 0.8;
    padding-block-end: 33px;
    flex-direction: column;
    margin-block-start: 0;

    @include media('>md') {
      flex-direction: row;
      padding-block-end: 0;
    }
  }

  &__footer-company {
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    padding-block-end: 28px;

    span {
      padding-block-end: 4px;
    }

    @include media('<md') {
      align-items: center;
    }
  }

  &__footer-copyright {
    padding-block-end: 20px;
  }

  &__footer-privacy {
    &:hover {
      color: var(--color-red);
    }
  }

  &__reviews-container {
    padding-inline: 30px;
  }
}

.slogans{
  margin-top: 15vh;
  color: #fff;

  @include media('<md') {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  &__row{
    display: grid;
    gap: 30;

    @include media('>=md') {
      grid-template-columns: 1fr 1fr;
      gap: 20;
    }

    @include media('>xl') {
      gap: 30;
    }

    &:last-child{
      display: flex;
      flex-direction: column-reverse;

      @include media('>=md') {
        display: grid;
        flex-direction: row;
        margin-top: -30px;
      }
      @include media('>lg') {
        margin-top: -60px;
      }
      @include media('>xl') {
        margin-top: -6%;
      }
    }
  }

  &__col{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  &__title{
    display: block;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;

    @include media('>lg') {
      font-size: 20px;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__description{
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;

    @include media('>lg') {
      font-size: 20px;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__list{
    list-style: disc;
    font-size: 18px;
    padding-left: 20px;
    margin-top: 20px;
    line-height: 1.75;

    @include media('>lg') {
      font-size: 20px;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__image{
    width: 100%;
    border-radius: 48px;

    border: 4px solid;

    &--1{
      border-color: var(--color-red-1);
      box-shadow: 0px 4px 60px 0px rgba(187, 38, 73, 0.4), 12px 12px 20px 0px rgba(187, 38, 73, 0.3) inset;
    }

    &--2{
      border-color: var(--color-blue);
      box-shadow: 0px 4px 60px 0px rgba(40, 63, 142, 0.4), 12px 12px 20px 0px rgba(40, 63, 142, 0.3) inset;
    }
  }
}

.container {
  position: relative;
  max-inline-size: calc(1378px + 300px);
  inline-size: 100%;
  padding-inline: 20px;

  @include media('>xl') {
    padding-inline: 150px;
  }

  &_without-padding-in-mobile {
    @include media('<md') {
      padding-inline: 0;
    }
  }
}

.politics{
  margin-top: 150px;
  margin-bottom: 40px;

  background: var(--color-white);
  border-radius: 48px;
  color: var(--color-black);

  padding-inline: 40px;
  padding-block: 40px;

  h1{
    font-size: 2em;
    margin-bottom: .75em;
    margin-top: 16px;
  }
  h2{
    font-size: 1.75em;
    margin-block: .5em;
  }
  h3{
    font-size: 1.5em;
    margin-block: .3em;
  }

  p{
    font-size: 1.1em;
    margin-block: 1em;
  }

  a{
    color: var(--color-red);
  }

  &__back{
    color: var(--color-red);
    
  }

  @include media('<md') {
    font-size: 13px;
    margin-top: 100px;

    padding-inline: 20px;
    padding-block: 20px;
    border-radius: 40px;
  }
}
.table-wrapper{
  overflow-x: auto;
}
.header-nav {
  position: fixed;
  left: 0;
  transform: translateY(-100%);
  transition: all .3s ease;
  top: 0;
  inline-size: 100%;
  padding-block: $header-block-size-mobile 20px;
  background: #3A2F50;
  background:var(--gradient-menu);
  opacity: 0;
  padding-inline: 25px 35px;
  display: none;

  @include media('>lg') {
    padding-block: 120px 20px;
    background: transparent;
    position: relative;
    inline-size: 100%;
    max-inline-size: 1150px;
    padding-inline: 30px 0px;
    align-items: center;
    display: flex;
    justify-content: end;
    opacity: 1;
    transform: translateY(0);
    padding-block: 20px;
  }

  @include media('>lg', '<xl') {
    padding-inline: 15px 0px;
  }

  @include media('>xl') {
    padding-inline: 75px 120px;
  }

  &_active {
    @include media('<lg') {
      display: block;
      transform: translateX(0%);
      opacity: 1;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .7);
    }
  }

  &__menu {
    display: flex;
    inline-size: 100%;
    padding-inline-start: 20px;
    flex-direction: column;
    justify-content: flex-start;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    align-items: center;

    @include media('>lg') {
      align-items: center;
      flex-direction: row;
      padding-inline-start: 0;
      justify-content: space-between;
      
    }
    @include media('>xl') {
      gap: 20px;
    }
  }

  &__link {
    color: var(--color-white);
    block-size: 100%;
    display: inline-block;
    line-height: 150%;
    padding-block: 14px;
    font-size: 16px;

    &:hover {
      color: var(--color-red);
    }

    @include media('>lg', '<xl') {
      font-size: #{fluid(14px, 16px, $mediaLg, $mediaXl)};
    }

    @include media('>xl') {
      font-size: 16px;
    }
  }

  &__item {
    &:first-child {
      padding-block-start: 4px;

      @include media('>lg') {
        padding-block-start: 0;
      }
    }

    &:last-child {
      padding-block-end: 4px;

      @include media('>lg') {
        padding-block-end: 0;
      }
    }
  }
}

.header-buttons {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  z-index: 10;

  &__menu-burger {
    display: block;
    padding-block: 10px;
    padding-inline: 10px;
    z-index: 10;

    @include media('>lg') {
      display: none;
    }
  }

  &__burger-btn {
    background: none;
    border: none;
    display: flex;

    img {
      width: 44px;
      height: 44px;

      &:last-child {
        display: none;
      }
    }
  }
}

.modal {
  top: 0;
  z-index: 12;
  position: fixed;
  inline-size: 100%;
  block-size: 100%;
  background: rgba(0,0,0,0.7);
  align-items: center;
  padding-block: 50px;
  display: none;
  justify-content: center;
  overflow-y: auto;

  &__tariff-selector-wrapper {
    position: relative;
  }

  &__tariff-selector-icon {
    position: absolute;
    right: 22px;
    top: 18px;

    &.active {
      transform: rotate(-90deg);
    }

    @include media('>md') {
      right: 20px;
      top: 17px;
    }

    @include media('>xl') {
      right: 20px;
      top: 24px;
    }
  }

  &__description {
    letter-spacing: 0.2px;
    padding-block-start: 24px;

    @include media('>md') {
      padding-block-start: 0;
    }

    @include media('>xl') {
      line-height: 150%;
    }
  }

  &__thanks-screen {
    display: none;
    flex-direction: column;
    max-width: 650px;
    max-height: 650px;

    &.active {
      display: flex;
    }
  }

  &_visible {
    display: grid;
  }

  &__line-or {
    font-weight: bold;
    color: rgba(248, 248, 253, 0.28);

    @include media('<md') {
      padding-inline: 5px;
    }
  }

  &__close-icon {
    position: absolute;
    right: 0;
    top: -35px;
    cursor: pointer;
    border: none;
    background: none;

    @include media('<xl') {
      inline-size: 40px;
      block-size: 40px;
      top: 10px;
      right: 10px;
      background: #666;
      border-radius: 50%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      inline-size: 30px;
      block-size: 30px;
    }
  }

  &__privacy-wrap {
    margin-block: 20px 30px;
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
      text-align: left;
      inline-size: 100%;
    }
  }

  &__privacy {
    font-size: 16px;
    color: var(--color-black);
    padding-inline-start: 10px;

    &_red {
      color: var(--color-red);
    }
  }

  &__body {
    background: var(--gradient-modal);
    border-radius: 48px;
    color: var(--color-white);
    position: relative;

    @include media('>xl') {
      background: var(--gradient-dropdown);
    }
  }

  &__close-btn {
    background: none;
    border: none;
    color: var(--color-white);
    font-size: 3rem;
    cursor: pointer;
    position: absolute;
    inset-block-start: 11px;
    inset-inline-end: 31px;

    &:hover {
      color: var(--color-red);
    }
  }

  &__tg-text {
    &:hover {
      color: var(--color-tg);
    }
  }

  &__wrap {
    padding-inline: 30px;
    padding-block: 30px;
    max-width: 1220px;
    display: none;

    &.active {
      display: grid;
    }

    @include media('>md') {
      padding-inline: 60px;
      padding-block: 60px;
    }

    @include media('>xl') {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      padding-inline: 63px;
      padding-block: 50px;
    }
  }

  &__exit-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media('>xl') {
      grid-column: span 2;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 20px;

    @include media('>md') {
      line-height: 150%;
    }

    @include media('>xl') {
      font-size: 32px;
      max-width: 830px;
    }
  }

  &__info,
  &__desktop-only {
    font-size: 18px;

    @include media('<md') {
      font-size: 16px;
    }

    @include media('>xl') {
      font-size: 24px;
    }
  }

  &__desktop-only {
    display: none;

    @include media('<md') {
      display: none;
    }

    @include media('>xl') {
      display: block;
    }
  }

  &__info {
    padding-block-end: 30px;
    display: flex;
    flex-direction: column;

    @include media('<md') {
      padding-block-end: 30px;
    }

    @include media('>xl') {
      padding-block-end: 60px;
    }
  }

  &__server {
    color: var(--color-red);
    padding-block-start: 10px;
  }

  &__desktop-only,
  &__tg-text,
  &__server {
    font-weight: 700;
  }

  &__mobile-reverse {
    display: flex;
    flex-direction: column-reverse;

    @include media('>xl') {
      flex-direction: column;
    }
  }

  &__tg-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block-start: 24px;

    @include media('>md') {
      flex-direction: row;
      padding-block-start: 0;
      margin-block-start: 24px;
    }

    @include media('>xl') {
      padding-block-start: 0;
      margin-block-start: 64px;
    }
  }

  &__icon {
    inline-size: 40px;
    margin-inline-end: 20px;

    @include media('>md') {
      margin-inline-end: 0;
      margin-block-end: 10px;
    }

    @include media('>xl') {
      margin-inline-end: 20px;
      margin-block-end: 0;
    }
  }

  &__contact-tg {
    align-items: center;
    display: grid;
  }

  &__line {
    block-size: 2px;
    background: rgba(248, 248, 253, 0.28);
  }

  &__lines {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr .2fr 1fr;
    align-items: center;
    text-align: center;
    opacity: 0.5;
    padding-block-start: 20px;

    @include media('<md') {
      padding-block-start: 20px;
    }

    @include media('>xl') {
      display: none;
    }
  }

  &__tg-hide {
    display: none;

    @include media('<md') {
      display: none;
    }

    @include media('>xl') {
      display: flex;
    }
  }

  &__tg-desktop {
    display: flex;
    justify-content: center;

    @include media('>md') {
      justify-content: center;
    }

    @include media('>xl') {
      display: none;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__tariff-close {
    display: inline-block; 
    border-right: 2px solid var(--color-white);
    border-bottom: 2px solid var(--color-white);
    inline-size: 10px;
    block-size: 10px;
    transform: rotate(44deg);
    position: absolute;
    inset-block-start: 20px;
    inset-inline-end: 28px;
  }
  
  &__selector-selected-option {
    position: relative;
    display: inline-block;
    font-size: 0.875rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    color: white;
    font-weight: bold;
    cursor: pointer;
    user-select: none;

    &.modal__input {
      .selector-selected-option_active {
        border: 2px solid #bb2649;
      }
    }
  }

  &__selector-dropdown {
    position: absolute;
    border-radius: 20px;
    padding-block: 10px;
    background: var(--color-gray-2);
    width: 100%;
    top: 47px;
    display: none;

    &.active {
      display: block;
    }

    @include media('>md') {
      top: 62px;
      padding-block: 25px;
    }
  }

  &__selector-option {
    padding-inline: 20px;
    padding-block: 16px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;

    span {
      font-weight: 700;
      flex-grow: 1;
      padding-inline-start: 5px;
    }

    &_selected {
      background: #BB264947;

      .modal__selector-option-icon {
        display: block;
      }
    }

    &:hover {
      background: rgba(227, 70, 106, 0.28);
    }
  }

  &__selector-option-icon {
    display: none;
    width: 20px;
    height: auto;
  }

  &__tariff-options {
    position: absolute;
    inset-block-start: 67px;
    inset-inline-start: 0;
    inline-size: 100%;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid var(--color-white);
    display: none;
  }

  &__tariff-option {
    padding-block: 16px;
    padding-inline: 20px;
    border-bottom: 1px solid var(--color-white);
    cursor: pointer;
    
    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: var(--color-red);
    }
  }

  &__tariff-uppercase {
    text-transform: uppercase;
    font-weight: 700;
  }

  &__input {
    border: 1px solid var(--color-white);
    border-radius: 28px;
    background: #ffffff30;
    outline: none;
    padding-block: 10px;
    padding-inline: 20px;
    inline-size: 100%;
    margin-block-end: 20px;
    font-size: 14px;
    line-height: 150%;
    font-weight: bold;
    color: var(--color-white);

    &::-webkit-input-placeholder {
      color: var(--color-white);
      font-weight: 400;
    }

    &_textarea {
      @include media('>xl') {
        max-width: 533px;
        min-width: 533px;
        max-height: 142px;
        min-height: 142px;
      }
    }

    @include media('>xl') {
      padding-block: 16px;
      font-size: 16px;
      margin-block-end: 30px;
    }
  }

  &__btn {
    color: var(--color-white);
    background: var(--color-red);
    border: none;
    border-radius: 48px;
    padding-block: 12px;
    padding-inline: 40px;
    font-size: 16px;
    line-height: 150%;
    cursor: pointer;
    font-weight: 700;
    position: relative;
    transition: transform .3s;

    &:hover {
      transform: scale(1.03);
      box-shadow: 4px 4px 10px #5e2e2e80;
    }

    @include media('>xl') {
      font-size: 16px;
      padding-block: 16px;
    }

    &--loading{
      color: darken($color: #bb2649, $amount: 15%);
      background: darken($color: #bb2649, $amount: 15%);

      &:hover {
        transform: none;
      }
  
      &::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
      }
    }
  }

  @keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
  }
  /* Pop-up */

  &__small{
    font-size: .75rem;
    text-align: center;
    margin-top: 10px;
    color: lighten($color: #bb2649, $amount: 45%);
  }
  
  &__popup {
    background: var(--gradient-dropdown);
    color: var(--color-white);
    border-radius: 48px;
  }
  
  &__popup-wrap {
    padding-block: 60px;
    padding-inline: 60px;
  }
  
  &__popup-right {
    text-align-last: right;
  }
  
  &__popup-checkmark {
    padding-block-end: 57px;
    padding-inline-start: 28px;
    inline-size: 85px;
    max-inline-size: 85px;
  }
  
  &__popup-ty {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 24px;

    @include media('>md') {
      font-size: 32px;
    }
  }
  
  &__popup-warn {
    padding-block: 28px;
    font-size: 1.1rem;
    line-height: 150%;
  
    @include media('>md') {
      font-size: 24px;
    }
  }
  
  &__popup-gl {
    font-weight: 700;
    font-size: 24px;
  
    @include media('<md') {
      font-size: 1.2rem;
    }
  }
  
  &__popup-btn {
    color: var(--color-white);
    background: var(--color-red);
    border: none;
    padding-block: 16px;
    padding-inline: 40px;
    border-radius: 48px;
    font-weight: 700;
    font-size: 16px;
    inline-size: 100%;
    margin-block-start: 20px;
    cursor: pointer;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 4px 4px 10px #5e2e2e80;
    }

    @include media('>md') {
      margin-block-start: 28px;
    }
  }
}

.shake-it {
  animation: 0.4s ease-in-out horizontal-shaking infinite;
}

.swiper-pagination-bullet {
  background: var(--color-red);
  inline-size: 16px;
  block-size: 16px;
  margin-left: 16px !important;
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}

.gradient-container {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.gradient-floater {
  z-index: 19;
  position: relative;
  width: 260vw;
  height: 260vw;


  @include media('>md') {
    width: 92vw;
    height: 99vw;
  }

  &_red-block {
    background: radial-gradient(circle, #8f0a297a -31%, #283f8e00 45%);
    animation: 5s floating-red linear infinite forwards;
  }

  &_blue-block {
    background: radial-gradient(circle, #253e81 -31%, #283f8e00 45%);
    animation: 5s floating-blue linear infinite forwards;
    z-index: 1;
    position: absolute;
    opacity: 0.8;
  }

  &:nth-child(13) {
    background: radial-gradient(circle, #482581 -31%, #283f8e00 45%);
  }
}

@keyframes floating-red {
  0% {
      transform: translate(0, 0);
  }
  25% {
      transform: translate(180px, 0);
  }
  50% {
      transform: translate(180px, 180px);
  }
  75% {
      transform: translate(0, 180px);
  }
  100% {
      transform: translate(0, 0);
  }
}

@keyframes floating-blue {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 150px);
  }
  50% {
    transform: translate(100px, 150px);
  }
  75% {
    transform: translate(0, 150px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* red blocks */
.gradient-floater {
  &_red-block {
    &:nth-child(1) {
      animation-duration: 8s;
      inset-block-start: -2%;
      inset-inline-end: 35%;

      @include media('>md') {
        .gradient-floater_blue-block {
          inset-block-start: 12%;
        }

        inset-block-start: -4%;
        inset-inline-end: -34%;
      }
    }

    &:nth-child(2) {
      animation-duration: 12s;
      inset-block-end: 0;
      inset-inline-start: 0;

      @include media('>md') {
        inset-block-end: 2%;
        inset-inline-start: -46%;
      }

      .gradient-floater_blue-block {
        inset-block-start: 12%;

        @include media('>md') {
          inset-block-start: 12%;
        }
      }
    }

    &:nth-child(3) {
      animation-duration: 10s;
      inset-block-end: -64px;
      inset-inline-start: -43%;

      @include media('>md') {
        inset-block-end: 6%;
        inset-inline-start: 39%;

        .gradient-floater_blue-block {
          inset-block-start: -40%;
        }
      }
    }

    &:nth-child(4) {
      animation-duration: 14s;
      inset-block-end: -70px;
      inset-inline-start: -49%;
    }

    &:nth-child(5) {
      animation-duration: 9s;
      inset-block-end: -44px;
      inset-inline-start: 42%;
    }

    &:nth-child(6) {
      position: absolute;
      animation-duration: 13s;
      inset-block-end: -84px;
      inset-inline-end: -43%;
    }

    &:nth-child(7) {
      animation-duration: 11s;
      inset-block-end: -2%;
      inset-inline-start: -123%;
      position: absolute;

      @include media('>md') {
        inset-block-end: -64px;
        inset-inline-start: -43%;
      }

      .gradient-floater_blue-block {
        inset-block-start: -25%;
      }
    }

    &:nth-child(8) {
      animation-duration: 8s;
      inset-block-start: -2%;
      inset-inline-start: 35%;
      position: absolute;

      @include media('>md') {
        .gradient-floater_blue-block {
          inset-block-start: 12%;
        }

        inset-block-start: -4%;
        inset-inline-start: -34%;
      }
    }
  }
}

@keyframes color-blinking-red {
  0% {
    background: rgba(187, 38, 73, 0.18);
  }
  50% {
    background: rgba(187, 38, 73, 0.38);
  }
  80% {
    background: rgba(187, 38, 73, 0.55);
  }
  100% {
    background: rgba(187, 38, 73, 0.48);
  }
}

@keyframes color-blinking-blue {
  0% {
    background: rgba(40, 63, 142, 0.48);
  }
  50% {
    background: rgba(40, 63, 142, 0.38);
  }
  80% {
    background: rgba(40, 63, 142, 0.50);
  }
  100% {
    background: rgba(40, 63, 142, 0.48);
  }
}