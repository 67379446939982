// Predefined Break-points
$mediaXl: 1350px;
$mediaLg: 992px;
$mediaMd: 768px;
$mediaXs: 576px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>xl': '(min-width: #{$mediaXl + 1})',
    '<xl': '(max-width: #{$mediaXl})',
    '>lg': '(min-width: #{$mediaLg + 1})',
    '<lg': '(max-width: #{$mediaLg})',
    '>md': '(min-width: #{$mediaMd + 1})',
    '>=md': '(min-width: #{$mediaMd})',
    '<md': '(max-width: #{$mediaMd})',
    '>xs': '(min-width: #{$mediaXs + 1})',
    '<xs': '(max-width: #{$mediaXs})',
  );
  @return map-get($condMap, $c);
}

// The media mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}
