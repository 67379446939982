@import "variables";
@import "styles";

body {
  position: relative;
}

::-webkit-scrollbar {
  width: 15px;
  padding: 0 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-red-3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-red);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-red-1);
}