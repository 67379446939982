:root {
 --color-white: #fff;
 --color-white-1: #f8f8fd;
 --color-white-2: rgb(255, 255, 255);
 --color-gray: #bac1d6;
 --color-gray-1: #8b8c8f;
 --color-gray-2: #25272E;
 --color-black: #000;
 --color-header: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,0,0.2665441176470589) 100%);
 --color-black-header: rgba(0, 0, 0, 0.3);
 --color-red: #bb2649;
 --color-red-1: rgb(187, 38, 73);
 --color-red-2: rgba(187, 38, 73, 0.28);
 --color-red-3: rgba(187, 38, 73, 0.12);
 --color-red-4: rgba(255, 255, 255, 0.24);
 --color-red-5: rgb(140, 24, 51);
 --color-blue: #283f8e;
 --color-blue-1: rgba(40, 63, 142, 0.08);
 --color-violet: #25272e;
 --color-violet-1: rgba(248, 248, 253, 0.28);
 --color-tg: #64c8fa;
 --gradient-dropdown: linear-gradient(133.63deg, rgba(187, 38, 73, 0) 47.645%,rgb(187, 38, 73) 152.218%),linear-gradient(29.28deg, rgb(58, 47, 80) 3.827%,rgb(39, 32, 36) 58.696%);
 --gradient-menu: linear-gradient(133.63deg, rgba(187, 38, 73, 0) 47.645%,rgb(110, 23, 43) 152.218%),linear-gradient(29.28deg, rgb(58, 47, 80) 3.827%,rgb(39, 32, 36) 58.696%);
 --gradient-modal: linear-gradient(90deg, rgba(57,48,80,1) 0%, rgba(40,27,42,1) 100%);
 --gradient-dropdown-btn: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(187,38,73,1) 0%, rgba(16,0,255,1) 100%);
 --bg: linear-gradient(185deg, rgb(37 40 52) 89%, rgb(30 21 42) 100%, rgb(37 40 64) 73%);
 --box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
}

* {
  font-family: "Manrope", sans-serif;
  padding-inline: 0;
  padding-block: 0;
  margin-inline: 0;
  margin-block: 0;
  box-sizing: border-box;
}

ul {
  padding-inline: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--color-white);
}

h1, h2, h3 {
  margin-inline: 0;
  margin-block: 0;
}

